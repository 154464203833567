<template>
  <div>
    <div class="columns is-multiline">
      <div
        class="column is-12"
        v-for="(option, indexOption) in data.option"
        :key="indexOption"
      >
        <div class="columns is-multiline">
          <div class="column is-6 answer-input">
            <template v-if="option.type == 'text'">
              <ValidationProvider
                :vid="`checkbox${indexSection}${indexAssessment}${indexOption}`"
                name="option"
                rules="required"
                v-slot="{ errors }"
              >
                <b-field :type="errors.length > 0 ? 'is-danger' : null">
                  <b-checkbox type="is-hcc" disabled></b-checkbox>
                  <b-input
                    expanded
                    type="text"
                    name="uploadImage"
                    v-model="option.title"
                  ></b-input>
                </b-field>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </template>
            <template v-else-if="option.type == 'image'">
              <ValidationProvider
                :vid="
                  `multipleimage${indexSection}${indexAssessment}${indexOption}`
                "
                name="option"
                rules="required"
                v-slot="{ errors }"
              >
                <b-field
                  :type="errors.length > 0 ? 'is-danger' : null"
                  class="file"
                >
                  <b-checkbox type="is-hcc" disabled></b-checkbox>
                  <span class="file-name">{{ option.file.name }}</span>
                  <b-upload
                    v-model="option.file"
                    @input="uploadFileToBlob($event, indexOption)"
                    accept="image/*"
                    name="uploadImage"
                  >
                    <a class="button is-hcc">
                      <b-icon icon="upload"></b-icon>
                      <span>Upload</span>
                    </a>
                  </b-upload>
                </b-field>
                <span class="required">{{ errors[0] }}</span>

                <img
                  src="../../../../assets/logo.png"
                  v-if="option.fileUrl == null"
                  alt="Image"
                />
                <img v-lazy="getUrlFile(option.fileUrl)" v-else alt="Image" />
              </ValidationProvider>
            </template>
          </div>
          <div class="column is-2 answer-input">
            <template v-if="rulesData.multipleChoiceType == 'Tally'">
              <ValidationProvider
                :vid="
                  `multiplepoint${indexSection}${indexAssessment}${indexOption}`
                "
                name="option"
                rules="required"
                v-slot="{ errors }"
              >
                <b-field :type="errors.length > 0 ? 'is-danger' : null">
                  <b-numberinput
                    controls-position="compact"
                    controls-rounded
                    type="is-hcc"
                    v-model="option.point"
                  ></b-numberinput>
                </b-field>
              </ValidationProvider>
            </template>
            <template v-else-if="rulesData.multipleChoiceType == 'Pass/Fail'">
              <ValidationProvider
                :vid="
                  `multiplepoint${indexSection}${indexAssessment}${indexOption}`
                "
                name="option"
                rules="required"
                v-slot="{ errors }"
              >
                <b-field :type="errors.length > 0 ? 'is-danger' : null">
                  <b-switch
                    @input="changeOptionTrueAnswer(option.optionId)"
                    :value="data.answerOption.includes(option.optionId)"
                    type="is-hcc"
                    :disabled="
                      isDisabledSwitch &&
                        !data.answerOption.includes(option.optionId)
                    "
                  ></b-switch>
                </b-field>
              </ValidationProvider>
            </template>
            <template v-else-if="rulesData.multipleChoiceType == 'Coding'">
              <ValidationProvider
                :vid="
                  `multiplecoding${indexSection}${indexAssessment}${indexOption}`
                "
                name="option"
                rules="required"
                v-slot="{ errors }"
              >
                <b-field :type="errors.length > 0 ? 'is-danger' : null">
                  <b-input
                    controls-position="compact"
                    controls-rounded
                    type="is-hcc"
                    v-model="option.coding"
                  ></b-input>
                </b-field>
              </ValidationProvider>
            </template>
          </div>
          <div class="column is-2 answer-input">
            <b-select v-model="option.type">
              <option
                v-for="(type, o) in optionTypeData"
                :key="o"
                :value="type.value"
              >
                {{ type.title }}
              </option>
            </b-select>
          </div>
          <div class="column is-2 answer-input">
            <b-button
              icon-left="times"
              @click="deleteOption(indexOption)"
            ></b-button>
          </div>

          <!-- For option id -->
          <div class="column is-12 answer-id">
            <b>Answer ID : {{ option.optionId }}</b>
          </div>
        </div>
      </div>
      <div class="column is-12">
        <b-field>
          <b-checkbox type="is-hcc" disabled></b-checkbox>
          <b-button class="addOption" type="is-hcc" @click="addOption()">
            ADD OPTION
          </b-button>
        </b-field>
      </div>

      <!-- For answer true or false data -->
      <div
        class="column is-12"
        v-if="
          title == 'Introduction Test' &&
            rulesData.multipleChoiceType == 'Pass/Fail'
        "
      >
        <strong>True Answer</strong>
        <br />
        <!-- <ValidationProvider
          :vid="`textTrueAnswer${indexOption}`"
          name="Text True Answer"
          rules="required"
          v-slot="{ errors }"
        > -->
        <ckeditor v-model="data.textTrueAnswer" :config="editorConfig" />
        <!-- <span class="required">{{ errors[0] }}</span>
        </ValidationProvider> -->

        <br />
        <br />

        <strong>False Answer</strong>
        <br />
        <!-- <ValidationProvider
          :vid="`textFalseAnswer${indexOption}`"
          name="Text False Answer"
          rules="required"
          v-slot="{ errors }"
        > -->
        <ckeditor v-model="data.textFalseAnswer" :config="editorConfig" />
        <!-- <span class="required">{{ errors[0] }}</span>
        </ValidationProvider> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
    indexSection: {
      default: () => {},
    },
    indexAssessment: {
      default: () => {},
    },
    title: {
      default: () => {},
    },
    rulesData: {
      default: () => {},
    },
    listEditedPhoto: {
      default: () => [],
    },
  },
  data() {
    return {
      isDisabledSwitch: false,
      optionTypeData: [
        {
          title: 'Text',
          value: 'text',
        },
        {
          title: 'Image',
          value: 'image',
        },
      ],
      editorConfig: {
        // The configuration of the editor.
        extraAllowedContent: 'div[class]',
        allowedContent: true,
        // filebrowserUploadMethod: "form",
        // filebrowserUploadUrl: process.env.VUE_APP_BACKEND_URL + "/api/ckeditor/upload-image",
        extraPlugins: 'justify,font',
      },
      errorMessage: '',
    }
  },
  mounted() {
    if (this.$route.name == 'editAssessmentSection') {
      if (this.rulesData.checkboxTypeAnswer == 'max') {
        if (this.data.answerOption.length == this.rulesData.checkboxMaxAnswer) {
          this.isDisabledSwitch = true
        } else {
          this.isDisabledSwitch = false
        }
      } else {
        this.isDisabledSwitch = false
      }
    }
  },
  methods: {
    // For error/danger alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },

    // For add option
    addOption() {
      let optionNumber = this.data.option.length

      this.data.option.push({
        title: '',
        type: 'text',
        optionNumber: optionNumber + 1,
        optionId:
          Math.floor(10000 + Math.random() * 90000) +
          Math.round(new Date().getTime() / 1000),
        optionAnswer: false,
        point: 0,
        coding: '',
        file: {
          name: null,
        },
        fileUrl: null,
        fileStatus: '',
      })
    },

    // For delete option
    deleteOption(indexOption) {
      if (
        this.$route.name == 'editAssessmentSection' &&
        this.data.option[indexOption].type == 'image'
      ) {
        this.listEditedPhoto.push({
          type: 'delete',
          questionId: this.data.questionId,
          fileData: '',
          filePath: this.data.option[indexOption].fileUrl,
          optionId: this.data.option[indexOption].optionId,
          optionNumber: this.data.option[indexOption].optionNumber,
        })
      }

      if (this.data.option.length == 2) {
        this.danger('Cannot delete this option')
      } else {
        this.data.option.splice(indexOption, 1)
      }
    },

    // For change option true answer
    changeOptionTrueAnswer(optionId) {
      if (!this.data.answerOption.includes(optionId)) {
        this.data.answerOption.push(optionId)
      } else {
        let index = this.data.answerOption.indexOf(optionId)
        this.data.answerOption.splice(index, 1)
      }

      if (this.rulesData.checkboxTypeAnswer[1] == true) {
        if (this.data.answerOption.length == this.rulesData.checkboxMaxAnswer) {
          this.isDisabledSwitch = true
        } else {
          this.isDisabledSwitch = false
        }
      } else {
        this.isDisabledSwitch = false
      }
    },

    // for upload file to blob
    uploadFileToBlob(file, indexOption) {
      if (this.$route.name == 'editAssessmentSection') {
        if (this.data.option[indexOption].fileUrl == null) {
          this.listEditedPhoto.push({
            type: 'create',
            questionId: this.data.questionId,
            fileData: file,
            filePath: '',
            optionId: this.data.option[indexOption].optionId,
            optionNumber: this.data.option[indexOption].optionNumber,
          })
        } else {
          if (this.data.option[indexOption].fileUrl.includes('blob:')) {
            let isPhotoEdited = false
            this.listEditedPhoto.map(photo => {
              if (this.data.option[indexOption].optionId == photo.optionId) {
                photo.fileData = file
                isPhotoEdited = true
              }
            })

            if (!isPhotoEdited) {
              this.listEditedPhoto.push({
                type: 'create',
                questionId: this.data.questionId,
                fileData: file,
                filePath: '',
                optionId: this.data.option[indexOption].optionId,
                optionNumber: this.data.option[indexOption].optionNumber,
              })
            }
          } else {
            this.listEditedPhoto.push({
              type: 'edit',
              questionId: this.data.questionId,
              fileData: file,
              filePath: this.data.option[indexOption].fileUrl,
              optionId: this.data.option[indexOption].optionId,
              optionNumber: this.data.option[indexOption].optionNumber,
            })
          }
        }
      }

      let blobUrl = URL.createObjectURL(file)

      this.data.option[indexOption].fileUrl = blobUrl
    },

    // For get url backend
    getUrlFile(file) {
      let urlType = file.split(':')
      if (this.$route.name == 'editAssessmentSection' && urlType[0] != 'blob') {
        return process.env.VUE_APP_BACKEND_URL + file
      }

      return file
    },
  },
}
</script>

<style lang="scss" scoped>
.answer {
  &-id {
    padding-top: 0;
    font-size: 14px;
    color: #7e7e7e;
  }

  &-input {
    padding-bottom: 0;
  }
}
</style>
