<template>
  <div>
    <h1 class="title">Rules</h1>
    <div class="box">
      <!-- For Rules Name Field -->
      <div class="columns is-12">
        <div class="column is-2">
          <b>Rules Name</b>
          <b-tooltip type="is-danger" label="This field is required">
            <span class="required">*</span>
          </b-tooltip>
        </div>
        <div class="column is-10">
          <b-input v-model="data.rulesName"></b-input>
        </div>
      </div>

      <!-- For Random Category -->
      <div class="columns is-12">
        <div class="column is-2">
          <b>Random Type</b>
          <b-tooltip type="is-danger" label="This field is required">
            <span class="required">*</span>
          </b-tooltip>
        </div>
        <div class="column is-10">
          <div class="columns is-multiline">
            <div class="column is-12">
              <b-checkbox
                expanded
                value="Random Question Group"
                v-model="data.randomType[0]"
                type="is-hcc"
                >Random Question Group</b-checkbox
              >
            </div>
            <div class="column is-12">
              <b-checkbox
                expanded
                value="Random Question"
                v-model="data.randomType[1]"
                type="is-hcc"
                >Random Question</b-checkbox
              >
            </div>
            <div class="column is-12">
              <b-checkbox
                value="Random Answer"
                v-model="data.randomType[2]"
                type="is-hcc"
                >Random Answer</b-checkbox
              >
            </div>
          </div>
        </div>
      </div>

      <!-- For question/section to show -->
      <template v-if="!data.randomType[0]">
        <div class="columns is-12">
          <div class="column is-2">
            <b>Question To Show</b>
            <b-tooltip type="is-danger" label="This field is required">
              <span class="required">*</span>
            </b-tooltip>
          </div>
          <div class="column is-10">
            <div class="columns is-multiline">
              <template>
                <div class="column is-12">
                  <b-input
                    expanded
                    type="number"
                    min="0"
                    v-model="data.randomQuestionNumber"
                  ></b-input>
                </div>
              </template>

              <!-- <div class="column is-12">
                <b-checkbox type="is-hcc" v-model="data.isUseTime"
                  >Use Time</b-checkbox
                >
              </div> -->
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="columns is-12">
          <div class="column is-2">
            <b>Question Group To Show</b>
            <b-tooltip type="is-danger" label="This field is required">
              <span class="required">*</span>
            </b-tooltip>
          </div>
          <div class="column is-10">
            <div class="columns is-multiline">
              <template>
                <div class="column is-12">
                  <b-input
                    expanded
                    type="number"
                    min="0"
                    v-model="data.randomQuestionGroupNumber"
                  ></b-input>
                </div>
              </template>

              <!-- <div class="column is-12">
                <b-checkbox type="is-hcc" v-model="data.isUseTime"
                  >Use Time</b-checkbox
                >
              </div> -->
            </div>
          </div>
        </div>
      </template>

      <div class="columns is-12">
        <div class="column is-2">
          <b>Question To Display</b>
          <b-tooltip type="is-danger" label="This field is required">
            <span class="required">*</span>
          </b-tooltip>
        </div>
        <div class="column is-10">
          <div class="columns is-multiline">
            <template>
              <div class="column is-12">
                <b-input
                  expanded
                  type="number"
                  min="1"
                  v-model="data.questionToDisplay"
                ></b-input>
              </div>
            </template>
          </div>
        </div>
      </div>

      <!-- For time -->
      <div class="columns is-12">
        <div class="column is-2">
          <b>Time</b>
          <b-tooltip type="is-danger" label="This field is required">
            <span class="required">*</span>
          </b-tooltip>
        </div>
        <div class="column is-10">
          <div class="columns is-multiline">
            <template v-if="data.isUseTime">
              <div class="column is-10">
                <b-input v-model="data.time" type="number" min="0"></b-input>
              </div>
              <div class="column is-2">Seconds</div>
            </template>

            <div class="column is-12">
              <b-checkbox type="is-hcc" v-model="data.isUseTime"
                >Use Time</b-checkbox
              >
            </div>
          </div>
        </div>
      </div>

      <!-- For Question Type -->
      <div class="columns is-12">
        <div class="column is-2">
          <b>Question Type</b>
          <b-tooltip type="is-danger" label="This field is required">
            <span class="required">*</span>
          </b-tooltip>
        </div>
        <div class="column is-10">
          <div class="columns">
            <div class="column is-3">
              <template
                v-if="sectionIntroductionTest.section[0].assessment.length > 0"
              >
                <SelectConfirmAssessmentSectionRules
                  v-model="data.questionType"
                />
              </template>
              <template v-else>
                <b-select v-model="data.questionType">
                  <option
                    v-for="(type, t) in questionTypeList"
                    :key="t"
                    :value="type"
                    >{{ type }}</option
                  >
                </b-select>
              </template>
            </div>
            <div class="column is-6 field">
              <template v-if="data.questionType == 'Multiple Choice'">
                <b-radio
                  type="is-hcc"
                  v-model="data.multipleChoiceType"
                  native-value="Pass/Fail"
                  >Pass/Fail</b-radio
                >
                <b-radio
                  type="is-hcc"
                  v-model="data.multipleChoiceType"
                  native-value="Tally"
                  >Tally</b-radio
                >
                <b-radio
                  type="is-hcc"
                  v-model="data.multipleChoiceType"
                  native-value="Coding"
                  >Coding</b-radio
                >
              </template>
            </div>
          </div>
        </div>
      </div>

      <!-- For Type Point -->
      <div class="columns is-12">
        <div class="column is-2">
          <b>Type Point</b>
          <b-tooltip type="is-danger" label="This field is required">
            <span class="required">*</span>
          </b-tooltip>
        </div>
        <div class="column is-10">
          <!-- For Essay Type Point -->
          <div v-if="data.questionType == 'Essay'">
            <b-switch
              type="is-hcc"
              @input="selectedQuestion('textfield')"
              :value="data.typePoint.includes('textfield')"
              >Textfield</b-switch
            >
            <!-- <br />
            <b-switch
              type="is-hcc"
              @input="selectedQuestion('textarea')"
              :value="data.typePoint.includes('textarea')"
              >Textarea</b-switch
            >-->
          </div>

          <!-- For Multiple Choice Type Point -->
          <div v-if="data.questionType == 'Multiple Choice'">
            <b-switch
              type="is-hcc"
              @input="selectedQuestion('question')"
              :value="data.typePoint.includes('question')"
              >Question</b-switch
            >
            <br />
            <b-switch
              type="is-hcc"
              @input="selectedQuestion('radio-button')"
              :value="data.typePoint.includes('radio-button')"
              >Radio Button</b-switch
            >
            <br />
            <b-switch
              type="is-hcc"
              @input="selectedQuestion('checkbox')"
              :value="data.typePoint.includes('checkbox')"
              >Checkbox</b-switch
            >
            <br />
            <div
              class="columns is-12"
              v-if="data.typePoint.includes('checkbox')"
            >
              <div class="column is-2" style="padding-top: 18px">
                <div class="field">
                  <b-checkbox v-model="data.checkboxTypeAnswer[0]" type="is-hcc"
                    >Min</b-checkbox
                  >
                </div>
                <div class="field">
                  <b-checkbox v-model="data.checkboxTypeAnswer[1]" type="is-hcc"
                    >Max</b-checkbox
                  >
                </div>
              </div>
              <div class="column is-3" v-if="!data.checkboxTypeAnswer[1]">
                <div class="field">
                  <b-numberinput
                    controls-position="compact"
                    controls-rounded
                    expanded
                    min="1"
                    type="is-hcc"
                    v-model="data.checkboxMinAnswer"
                    :disabled="data.checkboxTypeAnswer[0] != true"
                  ></b-numberinput>
                </div>
                <div class="field">
                  <b-numberinput
                    controls-position="compact"
                    controls-rounded
                    expanded
                    min="1"
                    type="is-hcc"
                    v-model="data.checkboxMaxAnswer"
                    :disabled="data.checkboxTypeAnswer[1] != true"
                  ></b-numberinput>
                </div>
              </div>
              <div class="column is-3" v-else>
                <div class="field">
                  <b-numberinput
                    controls-position="compact"
                    controls-rounded
                    expanded
                    min="1"
                    :max="data.checkboxMaxAnswer"
                    type="is-hcc"
                    v-model="data.checkboxMinAnswer"
                    :disabled="data.checkboxTypeAnswer[0] != true"
                  ></b-numberinput>
                </div>
                <div class="field">
                  <b-numberinput
                    controls-position="compact"
                    controls-rounded
                    expanded
                    :min="data.checkboxMinAnswer"
                    type="is-hcc"
                    v-model="data.checkboxMaxAnswer"
                    :disabled="data.checkboxTypeAnswer[1] != true"
                  ></b-numberinput>
                </div>
              </div>
              <div class="column is-3" style="padding-top: 18px">
                <div class="field">Answer</div>
                <div class="field">Answer</div>
              </div>
            </div>
          </div>

          <!-- For Other Type Point -->
          <div v-if="data.questionType == 'Other'">
            <b-switch
              type="is-hcc"
              @input="selectedQuestion('linear-scale')"
              :value="data.typePoint.includes('linear-scale')"
              >Linear Scale</b-switch
            >
            <br />
            <b-switch
              type="is-hcc"
              @input="selectedQuestion('ranking')"
              :value="data.typePoint.includes('ranking')"
              >Ranking</b-switch
            >
            <br />
            <div
              class="columns is-12"
              v-if="data.typePoint.includes('ranking')"
            >
              <div class="column is-2" style="padding-top: 18px">
                <div class="field">
                  <b-checkbox v-model="data.rankingTypeAnswer[0]" type="is-hcc"
                    >Min</b-checkbox
                  >
                </div>
                <div class="field">
                  <b-checkbox v-model="data.rankingTypeAnswer[1]" type="is-hcc"
                    >Max</b-checkbox
                  >
                </div>
              </div>
              <div class="column is-3" v-if="!data.rankingTypeAnswer[1]">
                <div class="field">
                  <b-numberinput
                    controls-position="compact"
                    controls-rounded
                    expanded
                    min="1"
                    type="is-hcc"
                    v-model="data.rankingMinAnswer"
                    :disabled="data.rankingTypeAnswer[0] != true"
                  ></b-numberinput>
                </div>
                <div class="field">
                  <b-numberinput
                    controls-position="compact"
                    controls-rounded
                    expanded
                    min="1"
                    type="is-hcc"
                    v-model="data.rankingMaxAnswer"
                    :disabled="data.rankingTypeAnswer[1] != true"
                  ></b-numberinput>
                </div>
              </div>
              <div class="column is-3" v-else>
                <div class="field">
                  <b-numberinput
                    controls-position="compact"
                    controls-rounded
                    expanded
                    min="1"
                    :max="data.rankingMaxAnswer"
                    type="is-hcc"
                    v-model="data.rankingMinAnswer"
                    :disabled="data.rankingTypeAnswer[0] != true"
                  ></b-numberinput>
                </div>
                <div class="field">
                  <b-numberinput
                    controls-position="compact"
                    controls-rounded
                    expanded
                    :min="data.rankingMinAnswer"
                    type="is-hcc"
                    v-model="data.rankingMaxAnswer"
                    :disabled="data.rankingTypeAnswer[1] != true"
                  ></b-numberinput>
                </div>
              </div>
              <div class="column is-3" style="padding-top: 18px">
                <div class="field">Answer</div>
                <div class="field">Answer</div>
              </div>
            </div>
            <div
              class="columns is-12"
              v-if="data.typePoint.includes('ranking')"
            >
              <div class="column is-2" style="padding-top: 18px">
                Set Ranking Point
              </div>
              <div class="column is-8">
                <div
                  class="columns is-12"
                  v-for="(point, p) in data.rankingPointCategory"
                  :key="p"
                >
                  <div class="column is-4">
                    <b-input
                      placeholder="Title"
                      v-model="point.title"
                    ></b-input>
                  </div>
                  <div class="column is-4">
                    <b-numberinput
                      controls-position="compact"
                      controls-rounded
                      v-model="point.point"
                      type="is-hcc"
                    ></b-numberinput>
                  </div>
                  <div class="column is-2" style="padding-top: 18px">Point</div>
                  <div
                    class="column is-2"
                    style="padding-top: 18px"
                    v-if="data.rankingPointCategory.length > 1"
                  >
                    <b-button type="is-danger" @click="deleteRulesPoint(p)"
                      >Delete</b-button
                    >
                  </div>
                </div>
              </div>
            </div>
            <div
              class="columns is-12"
              v-if="data.typePoint.includes('ranking')"
            >
              <div class="column is-10">
                <b-button type="is-hcc" expanded @click="addMoreRulesPoint"
                  >ADD MORE</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectConfirmAssessmentSectionRules from "@/components/SelectConfirm/SelectConfirmAssessmentSectionRules";

export default {
  components: {
    SelectConfirmAssessmentSectionRules
  },
  props: {
    data: {
      default: () => {}
    },
    sectionIntroductionTest: {
      default: () => {}
    }
  },
  data() {
    return {
      questionTypeList: ["Essay", "Multiple Choice", "Other"]
    };
  },
  methods: {
    testGanti() {
      this.$emit("clicked");
    },
    selectedQuestion(type) {
      if (!this.data.typePoint.includes(type)) {
        this.data.typePoint.push(type);
      } else {
        let index = this.data.typePoint.indexOf(type);
        this.data.typePoint.splice(index, 1);
      }
    },
    addMoreRulesPoint() {
      this.data.rankingPointCategory.push({
        title: "",
        point: 0
      });
    },
    deleteRulesPoint(index) {
      this.data.rankingPointCategory.splice(index, 1);
    }
  },
  watch: {
    "data.questionType": function() {
      this.data.typePoint = [];
    },
    "data.checkboxTypeAnswer": function() {
      this.data.checkboxMinAnswer = 1;
      this.data.checkboxMaxAnswer = 1;
    },
    "data.rankingTypeAnswer": function() {
      this.data.rankingMinAnswer = 1;
      this.data.rankingMaxAnswer = 1;
    }
  }
};
</script>
