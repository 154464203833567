<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-8">
        <template v-if="data.textRules.type == 'text'">
          <b-input
            type="text"
            v-model="data.answer"
            :disabled="title != 'Introduction Test' ? true : false"
          ></b-input>
        </template>
        <template v-else-if="data.textRules.type == 'email'">
          <b-input
            type="email"
            v-model="data.answer"
            :disabled="title != 'Introduction Test' ? true : false"
          ></b-input>
        </template>
        <template v-else-if="data.textRules.type == 'number'">
          <b-input
            type="number"
            v-model="data.answer"
            :disabled="title != 'Introduction Test' ? true : false"
          ></b-input>
        </template>
      </div>
      <div class="column is-4">
        <b-select v-model="data.textRules.type" @input="changeTextRules()">
          <option
            v-for="(optionText, index) in listOptionText"
            :value="optionText.value"
            :key="index"
            >{{ optionText.title }}</option
          >
        </b-select>
      </div>

      <template
        v-if="data.textRules.type == 'text' && title == 'Question Test'"
      >
        <!-- For select type of minimum/maximum -->
        <div class="column is-3">Type</div>
        <div class="column is-2">
          <b-select v-model="data.textRules.typeLength">
            <option
              v-for="(optionLength, index) in listOptionLength"
              :key="index"
              :value="optionLength.value"
              >{{ optionLength.title }}</option
            >
          </b-select>
        </div>
        <div class="column is-7"></div>

        <!-- For minimum -->
        <div class="column is-3">Minimum</div>
        <div class="column is-2">
          <b-numberinput
            controls-position="compact"
            controls-rounded
            min="0"
            type="is-hcc"
            v-model="data.textRules.min"
          ></b-numberinput>
        </div>
        <div class="column is-7"></div>

        <!-- For minimum -->
        <div class="column is-3">Maximum</div>
        <div class="column is-2">
          <b-numberinput
            controls-position="compact"
            controls-rounded
            min="0"
            type="is-hcc"
            v-model="data.textRules.max"
          ></b-numberinput>
        </div>
        <div class="column is-7"></div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {}
    },
    title: {
      default: () => {}
    }
  },
  data() {
    return {
      listOptionText: [
        {
          title: "Text",
          value: "text"
        },
        {
          title: "Email",
          value: "email"
        },
        {
          title: "Number",
          value: "number"
        }
      ],
      listOptionLength: [
        {
          title: "Character",
          value: "character"
        },
        {
          title: "Word",
          value: "word"
        }
      ]
    };
  },
  methods: {
    changeTextRules() {
      this.data.answer = "";
      this.data.textRules.minChar = 0;
      this.data.textRules.maxChar = 0;
    }
  }
};
</script>
