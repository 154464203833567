<template>
  <div>
    <h1 class="title">Introduction</h1>
    <div class="box">
      <!-- For Title Field -->
      <div class="columns is-12">
        <div class="column is-2">
          <b>Title</b>
          <b-tooltip type="is-danger" label="This field is required">
            <span class="required">*</span>
          </b-tooltip>
        </div>
        <div class="column is-10">
          <b-input v-model="data.title"></b-input>
        </div>
      </div>

      <!-- For Rules Description -->
      <div class="columns is-12">
        <div class="column is-2">
          <b>Description</b>
          <b-tooltip type="is-danger" label="This field is required">
            <span class="required">*</span>
          </b-tooltip>
        </div>
        <div class="column is-10">
          <ckeditor v-model="data.description" :config="editorConfig" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {}
    }
  },
  data() {
    return {
      editorConfig: {
        // The configuration of the editor.
        extraAllowedContent: "div[class]",
        allowedContent: true,
        // filebrowserUploadMethod: "form",
        // filebrowserUploadUrl: process.env.VUE_APP_BACKEND_URL + "/api/ckeditor/upload-image",
        extraPlugins: "justify,font"
      }
    };
  }
};
</script>
