<template>
  <section
    v-bind:class="{ main: true, index: activeStep < 2 }"
    v-if="!isLoading"
    ref="element"
  >
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(savePopup)">
        <div>
          <div class="columns is-multiline" v-if="!isLoading">
            <div class="column is-12">
              <h1 class="is-size-3"><b>Assessment Setup</b></h1>
            </div>
            <div class="column bar">
              <nav class="breadcrumb" aria-label="breadcrumbs">
                <ul>
                  <li>
                    <h3 class="is-size-6">Assessment</h3>
                  </li>
                  <li>
                    <router-link :to="{ name: `assessment` }">
                      <h3 class="is-size-6">Assessment Setup</h3>
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: `showAssessment` }">
                      <h3 class="is-size-6">Assessment Detail</h3>
                    </router-link>
                  </li>
                  <li>
                    <h3 class="is-size-6 breadcrumb-item">
                      <template v-if="$route.name == 'addAssessmentSection'"
                        >Add Section</template
                      >
                      <template
                        v-else-if="$route.name == 'editAssessmentSection'"
                        >Edit Section</template
                      >
                    </h3>
                  </li>
                </ul>
              </nav>
            </div>
            <div class="column bar">
              <b-field grouped position="is-right">
                <b-button
                  class="is-hcc"
                  native-type="submit"
                  v-if="activeStep == 3"
                  >Save</b-button
                >
              </b-field>
            </div>
          </div>
          <br />
          <br />
          <div class="columns">
            <div class="column is-12">
              <b-steps
                v-model="activeStep"
                :animated="isAnimated"
                :has-navigation="true"
                :rounded="false"
              >
                <!-- For Introduction -->
                <b-step-item
                  label="Introduction"
                  icon="star"
                  :clickable="
                    $route.name == 'editAssessmentSection' ? true : false
                  "
                  :type="{ 'is-hcc': isProfileSuccess }"
                >
                  <AssessmentIntroduction :data="introduction" />
                </b-step-item>

                <!-- For Rules -->
                <b-step-item
                  label="Rules"
                  icon="tasks"
                  :clickable="
                    $route.name == 'editAssessmentSection' ? true : false
                  "
                  :type="{ 'is-hcc': isProfileSuccess }"
                >
                  <AssessmentRules
                    :data="rules"
                    :isInEditMenu="isInEditMenu"
                    :sectionIntroductionTest="sectionIntroductionTest"
                  />
                </b-step-item>

                <!-- For Introduction Test -->
                <b-step-item
                  label="Introduction Test"
                  icon="list"
                  :clickable="
                    $route.name == 'editAssessmentSection' ? true : false
                  "
                  :type="{ 'is-hcc': isProfileSuccess }"
                >
                  <AssessmentQuestion
                    :data="sectionIntroductionTest"
                    :rulesData="rules"
                    :title="'Introduction Test'"
                  />
                </b-step-item>

                <!-- For Question Test -->
                <b-step-item
                  label="Question Test"
                  icon="check"
                  :clickable="
                    $route.name == 'editAssessmentSection' ? true : false
                  "
                  :type="{ 'is-hcc': isProfileSuccess }"
                >
                  <AssessmentQuestion
                    :data="sectionQuestionTest"
                    :rulesData="rules"
                    :title="'Question Test'"
                  />
                </b-step-item>

                <!-- For pagination -->
                <template slot="navigation" slot-scope="{ previous, next }">
                  <b-button
                    type="is-hcc"
                    class="prev"
                    :disabled="previous.disabled"
                    @click.prevent="previous.action"
                    >Prev</b-button
                  >

                  <!-- Next button in introduction tab -->
                  <b-button
                    type="is-hcc"
                    @click.prevent="next.action"
                    class="button-next"
                    v-if="activeStep == 0"
                    :disabled="
                      next.disabled ||
                        !introduction.title ||
                        !introduction.description
                    "
                    >Next</b-button
                  >

                  <!-- Next button in rules tab -->
                  <b-button
                    type="is-hcc"
                    @click.prevent="next.action"
                    class="button-next"
                    v-if="activeStep == 1"
                    :disabled="
                      next.disabled ||
                        !rules.rulesName ||
                        !rules.questionType ||
                        rules.typePoint.length < 1
                    "
                    >Next</b-button
                  >

                  <!-- Next button in assesment introduction tab -->
                  <template v-if="activeStep == 2">
                    <template v-if="checkValidationInIntroduction() == true">
                      <b-button
                        type="is-hcc"
                        @click.prevent="dangerValidation()"
                        class="button-next"
                        :disabled="
                          next.disabled ||
                            sectionIntroductionTest.section.length < 1 ||
                            sectionIntroductionTest.introduction.length < 1
                        "
                        >Next</b-button
                      >
                    </template>
                    <template v-else>
                      <b-button
                        type="is-hcc"
                        class="button-next"
                        @click.prevent="next.action"
                        :disabled="next.disabled"
                        >Next</b-button
                      >
                    </template>
                  </template>

                  <!-- Else -->
                  <b-button
                    type="is-hcc"
                    native-type="submit"
                    v-else-if="activeStep == 3"
                    class="button-next"
                    >Save</b-button
                  >
                </template>
              </b-steps>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </section>
</template>

<script>
import AssessmentIntroduction from "./assessment-step-form/AssessmentIntroduction";
import AssessmentRules from "./assessment-step-form/AssessmentRules";
import AssessmentQuestion from "./assessment-step-form/AssessmentQuestion";

export default {
  components: {
    AssessmentIntroduction,
    AssessmentRules,
    AssessmentQuestion
  },
  data() {
    return {
      // For Active Step
      activeStep: 0,

      // For Animated Step
      isAnimated: true,

      // When step is success
      isProfileSuccess: true,

      // FOr loading page
      isLoading: false,

      // For section id
      sectionId: "",

      // For error question notificatio
      errorQuestionNotification: "",

      // For Introduction
      introduction: {
        title: "",
        description: ""
      },

      // For Rules Data
      rules: {
        rulesName: "",
        time: 0,
        isUseTime: true,
        questionType: "",
        multipleChoiceType: "Pass/Fail",
        checkboxTypeAnswer: [false, false],
        checkboxMinAnswer: 1,
        checkboxMaxAnswer: 1,
        rankingTypeAnswer: [false, false],
        rankingMinAnswer: 1,
        rankingMaxAnswer: 1,
        randomQuestionGroupNumber: 0,
        randomQuestionNumber: 0,
        questionToDisplay: 1,
        randomType: [false, false, false],
        rankingPointCategory: [
          {
            title: "",
            point: 0
          }
        ],
        typePoint: []
      },

      // For Section Introduction Test
      sectionIntroductionTest: {
        assessment: [],
        section: [],
        introduction: "",
        listEditedPhotoQuestion: [],
        listEditedPhotoOption: [],
        deletedQuestion: [],
        deletedSection: []
      },

      // For Section Question Test
      sectionQuestionTest: {
        assessment: [],
        section: [],
        listEditedPhotoQuestion: [],
        listEditedPhotoOption: [],
        deletedQuestion: [],
        deletedSection: []
      },

      isEditing: false,

      isInEditMenu: false
    };
  },
  created() {
    if (this.$route.name == "editAssessmentSection") {
      this.fetchData();
    } else {
      this.addSection();
      this.isLoading = false;
    }
  },
  methods: {
    // For add section
    addSection() {
      let groupIntroduction = {
        groupNumber: 1,
        groupId:
          Math.floor(10000 + Math.random() * 90000) +
          Math.round(new Date().getTime() / 1000) +
          1111,
        isUseQuestion: false,
        question: "",
        questionType: "text",
        questionYoutubeLink: "",
        questionFreeText: "",
        questionFileType: "image",
        questionVideoPlayed: 0,
        questionVideoLimit: false,
        questionFile: [
          {
            file: {
              name: null
            },
            fileNumber: 1,
            fileType: "",
            fileId:
              Math.floor(10000 + Math.random() * 90000) +
              Math.round(new Date().getTime() / 1000),
            url: null,
            status: ""
          }
        ],
        assessment: []
      };

      let groupQuestion = {
        groupNumber: 1,
        groupId:
          Math.floor(10000 + Math.random() * 90000) +
          Math.round(new Date().getTime() / 1000) +
          2222,
        isUseQuestion: false,
        question: "",
        questionType: "text",
        questionYoutubeLink: "",
        questionFreeText: "",
        questionFileType: "image",
        questionVideoPlayed: 0,
        questionVideoLimit: false,
        questionFile: [
          {
            file: {
              name: null
            },
            fileNumber: 1,
            fileType: "",
            fileId:
              Math.floor(10000 + Math.random() * 90000) +
              Math.round(new Date().getTime() / 1000),
            url: null,
            status: ""
          }
        ],
        assessment: []
      };

      this.sectionIntroductionTest.section.push(groupIntroduction);
      this.sectionQuestionTest.section.push(groupQuestion);
    },

    // For reset question
    resetQuestion() {
      this.sectionIntroductionTest.section = [];
      this.sectionQuestionTest.section = [];
    },

    // For fetch data when edit
    async fetchData() {
      this.isInEditMenu = true;
      this.isLoading = true;
      const response = await this.$store.dispatch(
        "getAssessmentSection",
        this.$route.params.assessmentSectionId
      );
      this.sectionId = response.sectionId;
      this.introduction = response.introduction;
      this.rules = response.rules;
      this.sectionIntroductionTest.section = response.introductionGroup;
      this.sectionIntroductionTest.introduction =
        response.introductionPreviewTest;
      this.sectionQuestionTest.section = response.questionGroup;
      this.assessmentId = response.assessmentId;

      this.rules.typePoint = JSON.parse(this.rules.typePoint);
      this.rules.rankingPointCategory = JSON.parse(
        this.rules.rankingPointCategory
      );

      this.isLoading = false;
    },

    // For check validation in introduction
    checkValidationInIntroduction() {
      // let error = 0;
      // let errorQuestion = "";

      // if (
      //   this.rules.questionType == "Multiple Choice" &&
      //   this.rules.typePoint.includes("checkbox")
      // ) {
      //   this.sectionIntroductionTest.assessment.map((assessment, index) => {
      //     if (
      //       assessment.option.length <= this.rules.checkboxMaxAnswer ||
      //       assessment.option.length <= this.rules.checkboxMinAnswer
      //     ) {
      //       error += 1;
      //       errorQuestion = "Question " + (index + 1);
      //     }
      //   });
      // }

      // if (
      //   this.rules.questionType == "Other" &&
      //   this.rules.typePoint.includes("ranking")
      // ) {
      //   this.sectionIntroductionTest.assessment.map((assessment, index) => {
      //     if (
      //       assessment.rankingOption.length <= this.rules.rankingMaxAnswer &&
      //       assessment.rankingOption.length <= this.rules.rankingMinAnswer
      //     ) {
      //       error += 1;
      //       errorQuestion = "Question " + (index + 1);
      //     }
      //   });
      // }

      // if (error != 0) {
      //   this.errorQuestionNotification = errorQuestion;

      //   return true;
      // }

      return false;
    },

    // For danger validation text
    dangerValidation() {
      this.danger(
        "Total option in " +
          this.errorQuestionNotification +
          " less than rules min or max data"
      );
    },

    // For save alert
    savePopup() {
      this.$buefy.dialog.confirm({
        title: "Save Assessment",
        message: `Are you sure want to save this assessment?`,
        cancelText: "No, cancel it!",
        confirmText: "Yes, save it!",
        type: "is-success",
        onConfirm: () => this.createSection()
      });
    },

    // For save section
    async createSection() {
      let error = 0;
      let errorQuestion = "";

      if (
        this.rules.questionType == "Multiple Choice" &&
        this.rules.typePoint.includes("checkbox")
      ) {
        this.sectionIntroductionTest.section.map(section => {
          section.assessment.map((assessment, indexAssessment) => {
            if (
              this.rules.checkboxTypeAnswer[0] == true &&
              this.rules.checkboxTypeAnswer[1] == false
            ) {
              if (assessment.option.length < this.rules.checkboxMinAnswer) {
                error += 1;
                errorQuestion = "Question " + (indexAssessment + 1);
              }
            } else {
              if (assessment.option.length < this.rules.checkboxMaxAnswer) {
                error += 1;
                errorQuestion = "Question " + (indexAssessment + 1);
              }
            }
          });
        });
        this.sectionQuestionTest.section.map(section => {
          section.assessment.map((assessment, indexAssessment) => {
            if (
              this.rules.checkboxTypeAnswer[0] == true &&
              this.rules.checkboxTypeAnswer[1] == false
            ) {
              if (assessment.option.length < this.rules.checkboxMinAnswer) {
                error += 1;
                errorQuestion = "Question " + (indexAssessment + 1);
              }
            } else {
              if (assessment.option.length < this.rules.checkboxMaxAnswer) {
                error += 1;
                errorQuestion = "Question " + (indexAssessment + 1);
              }
            }
          });
        });
      }

      if (
        this.rules.questionType == "Other" &&
        this.rules.typePoint.includes("ranking")
      ) {
        this.sectionIntroductionTest.section.map(section => {
          section.assessment.map((assessment, indexAssessment) => {
            if (
              this.rules.rankingTypeAnswer[0] == true &&
              this.rules.rankingTypeAnswer[1] == false
            ) {
              if (
                assessment.rankingOption.length < this.rules.rankingMinAnswer
              ) {
                error += 1;
                errorQuestion = "Question " + (indexAssessment + 1);
              }
            } else {
              if (
                assessment.rankingOption.length < this.rules.rankingMaxAnswer
              ) {
                error += 1;
                errorQuestion = "Question " + (indexAssessment + 1);
              }
            }
          });
        });
        this.sectionQuestionTest.section.map(section => {
          section.assessment.map((assessment, indexAssessment) => {
            if (
              this.rules.rankingTypeAnswer[0] == true &&
              this.rules.rankingTypeAnswer[1] == false
            ) {
              if (
                assessment.rankingOption.length < this.rules.rankingMinAnswer
              ) {
                error += 1;
                errorQuestion = "Question " + (indexAssessment + 1);
              }
            } else {
              if (
                assessment.rankingOption.length < this.rules.rankingMaxAnswer
              ) {
                error += 1;
                errorQuestion = "Question " + (indexAssessment + 1);
              }
            }
          });
        });
      }

      if (error != 0) {
        this.errorQuestionNotification = errorQuestion;
        this.dangerValidation();
        return false;
      } else {
        const loadingComponent = this.$buefy.loading.open({
          container: this.isFullPage ? null : this.$refs.element.$el
        });
        if (this.$route.name == "addAssessmentSection") {
          await this.$store
            .dispatch("saveAssessmentSection", {
              // For Assessment Id
              assessmentId: this.$route.params.assessmentId,

              // For introduction
              sectionId:
                Math.floor(10000 + Math.random() * 90000) +
                Math.round(new Date().getTime() / 1000),
              sectionName: this.introduction.title,
              sectionDescription: this.introduction.description,

              // For rules
              rulesName: this.rules.rulesName,
              time: this.rules.time,
              isUseTime: this.rules.isUseTime,
              questionType: this.rules.questionType,
              multipleChoiceType: this.rules.multipleChoiceType,
              checkboxTypeAnswer: this.rules.checkboxTypeAnswer,
              checkboxMinAnswer: this.rules.checkboxMinAnswer,
              checkboxMaxAnswer: this.rules.checkboxMaxAnswer,
              rankingTypeAnswer: this.rules.rankingTypeAnswer,
              rankingMinAnswer: this.rules.rankingMinAnswer,
              rankingMaxAnswer: this.rules.rankingMaxAnswer,
              randomType: this.rules.randomType,
              randomQuestionGroupNumber: this.rules.randomQuestionGroupNumber,
              randomQuestionNumber: this.rules.randomQuestionNumber,
              questionToDisplay: this.rules.questionToDisplay,
              rankingPointCategory: this.rules.rankingPointCategory,
              typePoint: this.rules.typePoint,

              // For Asessment Introduction Test
              sectionIntroductionTest: this.sectionIntroductionTest.section,

              introduction: this.sectionIntroductionTest.introduction,

              // For Asessment Question Test
              sectionQuestionTest: this.sectionQuestionTest.section
            })
            .then(response => {
              if (response.status == 200) {
                this.isEditing = true;
                this.success("Successfully create assessment");
                loadingComponent.close();
                this.$router.push({ name: "showAssessment" });
              } else {
                this.danger("Failed to create assessment");
              }
            })
            .catch(() => {
              this.danger("Failed to create a new assessment");
              loadingComponent.close();
            });
        } else {
          await this.$store
            .dispatch("updateAssessmentSection", {
              // For id section
              id: this.$route.params.assessmentSectionId,

              // For Assessment Id
              assessmentId: this.$route.params.assessmentId,

              // For introduction
              sectionId: this.sectionId,
              sectionName: this.introduction.title,
              sectionDescription: this.introduction.description,

              // For rules
              rulesName: this.rules.rulesName,
              time: this.rules.time,
              isUseTime: this.rules.isUseTime,
              questionType: this.rules.questionType,
              multipleChoiceType: this.rules.multipleChoiceType,
              checkboxTypeAnswer: this.rules.checkboxTypeAnswer,
              checkboxMinAnswer: this.rules.checkboxMinAnswer,
              checkboxMaxAnswer: this.rules.checkboxMaxAnswer,
              rankingTypeAnswer: this.rules.rankingTypeAnswer,
              rankingMinAnswer: this.rules.rankingMinAnswer,
              rankingMaxAnswer: this.rules.rankingMaxAnswer,
              randomType: this.rules.randomType,
              randomQuestionGroupNumber: this.rules.randomQuestionGroupNumber,
              randomQuestionNumber: this.rules.randomQuestionNumber,
              questionToDisplay: this.rules.questionToDisplay,
              rankingPointCategory: this.rules.rankingPointCategory,
              typePoint: this.rules.typePoint,

              // For Asessment Introduction Test
              sectionIntroductionTest: this.sectionIntroductionTest.section,

              // For list edited photo in introduction test
              assessmentIntroductionListEditedPhotoQuestion: this
                .sectionIntroductionTest.listEditedPhotoQuestion,
              assessmentIntroductionListEditedPhotoOption: this
                .sectionIntroductionTest.listEditedPhotoOption,

              // For list deleted question in introduction test
              assessmentIntroductionListDeletedSection: this
                .sectionIntroductionTest.deletedSection,
              assessmentIntroductionListDeletedQuestion: this
                .sectionIntroductionTest.deletedQuestion,

              // For introduction in introduction test
              introduction: this.sectionIntroductionTest.introduction,

              // For Asessment Question Test
              sectionQuestionTest: this.sectionQuestionTest.section,

              // For list edited photo in question test
              assessmentQuestionListEditedPhotoQuestion: this
                .sectionQuestionTest.listEditedPhotoQuestion,
              assessmentQuestionListEditedPhotoOption: this.sectionQuestionTest
                .listEditedPhotoOption,

              // For list deleted question in question test
              assessmentQuestionListDeletedSection: this.sectionQuestionTest
                .deletedSection,
              assessmentQuestionListDeletedQuestion: this.sectionQuestionTest
                .deletedQuestion
            })
            .then(response => {
              if (response.status == 200) {
                this.isEditing = true;
                this.success("Successfully update assessment");
                loadingComponent.close();
                this.$router.push({ name: "showAssessment" });
              } else {
                this.danger("Failed to update assessment");
              }
            })
            .catch(() => {
              this.danger("Failed to update assessment");
              loadingComponent.close();
            });
        }
      }
    },

    changeQuestion() {
      this.sectionIntroductionTest.section = [];
      this.sectionQuestionTest.section = [];

      this.addSection();
    },

    // For succes alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-success"
      });
    },

    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-danger"
      });
    }
  },
  watch: {
    "rules.questionType": function() {
      if (!this.isInEditMenu) {
        if (this.$route.name == "editAssessmentSection") {
          // For section introduction test
          let isListedIntroductionTest = false;
          this.sectionIntroductionTest.section.map(section => {
            section.assessment.map(assessment => {
              if (
                assessment.questionType != "text" &&
                assessment.questionType != "youtube" &&
                assessment.questionType != "free_text" &&
                assessment.questionType != "text_youtube"
              ) {
                this.sectionIntroductionTest.listEditedPhotoQuestion.push({
                  type: "delete-question",
                  questionId: assessment.questionId,
                  fileData: "",
                  filePath: "",
                  fileNumber: ""
                });
              }

              if (!isListedIntroductionTest) {
                if (
                  assessment.type == "checkbox" ||
                  assessment.type == "radio-button"
                ) {
                  let isOptionImage = false;
                  assessment.option.map(option => {
                    if (option.type == "image") {
                      isOptionImage = true;
                    }
                  });

                  if (isOptionImage) {
                    this.data.listEditedPhotoQuestion.push({
                      type: "delete-question",
                      questionId: assessment.questionId,
                      fileData: "",
                      filePath: "",
                      fileNumber: ""
                    });
                  }
                }
              }
            });
          });

          if (this.$route.name == "editAssessmentSection") {
            this.sectionIntroductionTest.section.map(section => {
              if (section.id != null) {
                this.sectionIntroductionTest.deletedSection.push(section.id);
              }
            });
          }

          // For section question test
          let isListedQuestionTest = false;
          this.sectionQuestionTest.section.map(section => {
            section.assessment.map(assessment => {
              if (
                assessment.questionType != "text" &&
                assessment.questionType != "youtube" &&
                assessment.questionType != "free_text" &&
                assessment.questionType != "text_youtube"
              ) {
                this.sectionQuestionTest.listEditedPhotoQuestion.push({
                  type: "delete-question",
                  questionId: assessment.questionId,
                  fileData: "",
                  filePath: "",
                  fileNumber: ""
                });
              }

              if (!isListedQuestionTest) {
                if (
                  assessment.type == "checkbox" ||
                  assessment.type == "radio-button"
                ) {
                  let isOptionImage = false;
                  assessment.option.map(option => {
                    if (option.type == "image") {
                      isOptionImage = true;
                    }
                  });

                  if (isOptionImage) {
                    this.data.listEditedPhotoQuestion.push({
                      type: "delete-question",
                      questionId: assessment.questionId,
                      fileData: "",
                      filePath: "",
                      fileNumber: ""
                    });
                  }
                }
              }
            });
          });

          if (this.$route.name == "editAssessmentSection") {
            this.sectionQuestionTest.section.map(section => {
              if (section.id != null) {
                this.sectionQuestionTest.deletedSection.push(section.id);
              }
            });
          }
        }

        this.changeQuestion();
      }

      this.isInEditMenu = false;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEditing == false) {
      const answer = window.confirm(
        "Do you really want to leave? you have unsaved changes!"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
};
</script>
<style>
.prev {
  margin-left: 1%;
}

.button-next {
  margin-right: 1%;
}

.step-items {
  padding-left: 20%;
  padding-right: 20%;
}

.step-title {
  font-size: 14px !important;
}
</style>
