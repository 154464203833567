var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('b-field',[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{attrs:{"name":"option","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-numberinput',{attrs:{"controls-position":"compact","controls-rounded":"","min":"0","max":"1","type":"is-hcc"},model:{value:(_vm.data.min),callback:function ($$v) {_vm.$set(_vm.data, "min", $$v)},expression:"data.min"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"column is-1 to"},[_vm._v("to")]),_c('div',{staticClass:"column is-3"},[_c('ValidationProvider',{attrs:{"name":"questionMax","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-numberinput',{attrs:{"controls-position":"compact","controls-rounded":"","min":"2","max":"10","type":"is-hcc"},model:{value:(_vm.data.max),callback:function ($$v) {_vm.$set(_vm.data, "max", $$v)},expression:"data.max"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-1"},[_vm._v(" "+_vm._s(_vm.data.min)+" ")]),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"name":"option1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.data.labelMin),callback:function ($$v) {_vm.$set(_vm.data, "labelMin", $$v)},expression:"data.labelMin"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-1"},[_vm._v(" "+_vm._s(_vm.data.max)+" ")]),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"name":"option2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.data.labelMax),callback:function ($$v) {_vm.$set(_vm.data, "labelMax", $$v)},expression:"data.labelMax"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }