<template>
  <div>
    <div class="select-confirm">
      <span class="select is-fullwidth">
        <select expanded :value="value" @change="showConfirmation">
          <option
            v-for="(type, t) in questionTypeList"
            :key="t"
            :value="type"
            >{{ type }}</option
          >
        </select>
      </span>
      <div v-if="showConfirmationDialog" class="confirmation-dialog"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: () => {}
    }
  },
  data() {
    return {
      questionTypeList: ["Essay", "Multiple Choice", "Other"],
      showConfirmationDialog: false,
      unconfirmedValue: ""
    };
  },
  methods: {
    cancelSelection() {
      this.showConfirmationDialog = false;
      this.unconfirmedValue = this.value;
    },

    confirmSelection() {
      this.$emit("input", this.unconfirmedValue);
      this.showConfirmationDialog = false;
    },

    showConfirmation(e) {
      this.changeQuestionPopup();
      this.unconfirmedValue = e.currentTarget.value;
      this.showConfirmationDialog = true;
    },

    // For action popup when chane question type
    changeQuestionPopup() {
      this.$buefy.dialog.confirm({
        title: "Change Question",
        message: `This action will delete all of your history question`,
        cancelText: "No, cancel it!",
        confirmText: "Yes, change it!",
        type: "is-success",
        onConfirm: () => this.confirmSelection(),
        onCancel: () => this.cancelSelection()
      });
    }
  }
};
</script>
