var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('section',{ref:"element",class:{ main: true, index: _vm.activeStep < 2 }},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.savePopup)}}},[_c('div',[(!_vm.isLoading)?_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[_c('h1',{staticClass:"is-size-3"},[_c('b',[_vm._v("Assessment Setup")])])]),_c('div',{staticClass:"column bar"},[_c('nav',{staticClass:"breadcrumb",attrs:{"aria-label":"breadcrumbs"}},[_c('ul',[_c('li',[_c('h3',{staticClass:"is-size-6"},[_vm._v("Assessment")])]),_c('li',[_c('router-link',{attrs:{"to":{ name: "assessment" }}},[_c('h3',{staticClass:"is-size-6"},[_vm._v("Assessment Setup")])])],1),_c('li',[_c('router-link',{attrs:{"to":{ name: "showAssessment" }}},[_c('h3',{staticClass:"is-size-6"},[_vm._v("Assessment Detail")])])],1),_c('li',[_c('h3',{staticClass:"is-size-6 breadcrumb-item"},[(_vm.$route.name == 'addAssessmentSection')?[_vm._v("Add Section")]:(_vm.$route.name == 'editAssessmentSection')?[_vm._v("Edit Section")]:_vm._e()],2)])])])]),_c('div',{staticClass:"column bar"},[_c('b-field',{attrs:{"grouped":"","position":"is-right"}},[(_vm.activeStep == 3)?_c('b-button',{staticClass:"is-hcc",attrs:{"native-type":"submit"}},[_vm._v("Save")]):_vm._e()],1)],1)]):_vm._e(),_c('br'),_c('br'),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12"},[_c('b-steps',{attrs:{"animated":_vm.isAnimated,"has-navigation":true,"rounded":false},scopedSlots:_vm._u([{key:"navigation",fn:function(ref){
var previous = ref.previous;
var next = ref.next;
return [_c('b-button',{staticClass:"prev",attrs:{"type":"is-hcc","disabled":previous.disabled},on:{"click":function($event){$event.preventDefault();return previous.action.apply(null, arguments)}}},[_vm._v("Prev")]),(_vm.activeStep == 0)?_c('b-button',{staticClass:"button-next",attrs:{"type":"is-hcc","disabled":next.disabled ||
                      !_vm.introduction.title ||
                      !_vm.introduction.description},on:{"click":function($event){$event.preventDefault();return next.action.apply(null, arguments)}}},[_vm._v("Next")]):_vm._e(),(_vm.activeStep == 1)?_c('b-button',{staticClass:"button-next",attrs:{"type":"is-hcc","disabled":next.disabled ||
                      !_vm.rules.rulesName ||
                      !_vm.rules.questionType ||
                      _vm.rules.typePoint.length < 1},on:{"click":function($event){$event.preventDefault();return next.action.apply(null, arguments)}}},[_vm._v("Next")]):_vm._e(),(_vm.activeStep == 2)?[(_vm.checkValidationInIntroduction() == true)?[_c('b-button',{staticClass:"button-next",attrs:{"type":"is-hcc","disabled":next.disabled ||
                          _vm.sectionIntroductionTest.section.length < 1 ||
                          _vm.sectionIntroductionTest.introduction.length < 1},on:{"click":function($event){$event.preventDefault();return _vm.dangerValidation()}}},[_vm._v("Next")])]:[_c('b-button',{staticClass:"button-next",attrs:{"type":"is-hcc","disabled":next.disabled},on:{"click":function($event){$event.preventDefault();return next.action.apply(null, arguments)}}},[_vm._v("Next")])]]:(_vm.activeStep == 3)?_c('b-button',{staticClass:"button-next",attrs:{"type":"is-hcc","native-type":"submit"}},[_vm._v("Save")]):_vm._e()]}}],null,true),model:{value:(_vm.activeStep),callback:function ($$v) {_vm.activeStep=$$v},expression:"activeStep"}},[_c('b-step-item',{attrs:{"label":"Introduction","icon":"star","clickable":_vm.$route.name == 'editAssessmentSection' ? true : false,"type":{ 'is-hcc': _vm.isProfileSuccess }}},[_c('AssessmentIntroduction',{attrs:{"data":_vm.introduction}})],1),_c('b-step-item',{attrs:{"label":"Rules","icon":"tasks","clickable":_vm.$route.name == 'editAssessmentSection' ? true : false,"type":{ 'is-hcc': _vm.isProfileSuccess }}},[_c('AssessmentRules',{attrs:{"data":_vm.rules,"isInEditMenu":_vm.isInEditMenu,"sectionIntroductionTest":_vm.sectionIntroductionTest}})],1),_c('b-step-item',{attrs:{"label":"Introduction Test","icon":"list","clickable":_vm.$route.name == 'editAssessmentSection' ? true : false,"type":{ 'is-hcc': _vm.isProfileSuccess }}},[_c('AssessmentQuestion',{attrs:{"data":_vm.sectionIntroductionTest,"rulesData":_vm.rules,"title":'Introduction Test'}})],1),_c('b-step-item',{attrs:{"label":"Question Test","icon":"check","clickable":_vm.$route.name == 'editAssessmentSection' ? true : false,"type":{ 'is-hcc': _vm.isProfileSuccess }}},[_c('AssessmentQuestion',{attrs:{"data":_vm.sectionQuestionTest,"rulesData":_vm.rules,"title":'Question Test'}})],1)],1)],1)])])])]}}],null,false,716550529)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }