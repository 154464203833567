<template>
  <div>
    <div class="columns is-multiline">
      <div
        class="column is-12"
        v-for="(ranking, indexRanking) in data.rankingOption"
        :key="indexRanking"
      >
        <div class="columns is-multiline">
          <!-- For Indexing -->
          <div
            class="column is-1 answer-input"
            style="padding-top: 18px; padding-left:36px;"
          >
            {{ indexRanking + 1 }}
          </div>

          <!-- For Option Title -->
          <div class="column is-5 answer-input">
            <ValidationProvider
              :vid="`rankingOption${indexRanking}`"
              name="rankingOption"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input
                  type="text"
                  v-model="data.rankingOption[indexRanking].option"
                ></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <!-- For autocomplete (rules ranking) -->
          <div class="column is-3 answer-input">
            <ValidationProvider
              :vid="`rankingAutocomplete${indexRanking}`"
              name="rankingAutocomplete"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-taginput
                  v-model="data.rankingOption[indexRanking].rulesTitle"
                  :data="rankingTags"
                  :open-no-focus="true"
                  autocomplete
                  maxtags="1"
                  placeholder="Type to search"
                  @typing="getRankingData"
                  @input="getPointData($event, indexRanking)"
                  @remove="removePointData(indexRanking)"
                ></b-taginput>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <!-- For Point -->
          <div class="column is-2 answer-input">
            <ValidationProvider
              :vid="`rankingPoint${indexRanking}`"
              name="rankingPoint"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-numberinput
                  controls-position="compact"
                  controls-rounded
                  type="is-hcc"
                  v-model="data.rankingOption[indexRanking].point"
                ></b-numberinput>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <!-- For button -->
          <div class="column is-1">
            <b-button
              icon-left="times"
              @click="deleteRankingOption(indexRanking)"
            ></b-button>
          </div>

          <!-- For ranking id -->
          <div class="column is-12 answer-id">
            <b
              >Answer ID :
              {{
                ranking.rankingId == "" || ranking.rankingId == null
                  ? generateRankingId(ranking)
                  : ranking.rankingId
              }}</b
            >
          </div>
        </div>
      </div>
      <div class="column is-12">
        <b-field>
          <b-button class="addOption" @click="addRankingOption()"
            >ADD OPTION</b-button
          >
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {}
    },
    indexData: {
      default: () => {}
    },
    rankingPointCategory: {
      default: () => []
    }
  },
  data() {
    return {
      rankingTags: []
    };
  },
  methods: {
    // For danger/error alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: "is-danger"
      });
    },

    // For add ranking option
    addRankingOption() {
      this.data.rankingOption.push({
        rankingId:
          Math.floor(10000 + Math.random() * 90000) +
          Math.round(new Date().getTime() / 1000),
        option: "",
        rulesTitle: [],
        point: 0
      });
    },

    // For generate rankingId
    generateRankingId(ranking) {
      ranking.rankingId =
        Math.floor(10000 + Math.random() * 90000) +
        Math.round(new Date().getTime() / 1000) +
        2183;
      return ranking;
    },

    // For delete ranking option
    deleteRankingOption(indexRanking) {
      if (this.data.rankingOption.length == 1) {
        this.danger("Cannot delete this ranking option");
      } else {
        this.data.rankingOption.splice(indexRanking, 1);
      }
    },

    // For get ranking data
    getRankingData(value) {
      let rankingTitle = [];
      this.rankingPointCategory.map(ranking => {
        rankingTitle.push(ranking.title);
      });

      this.rankingTags = rankingTitle;

      if (value) {
        var rankingTags = this.rankingTags.filter(option => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(value.toLowerCase()) >= 0
          );
        });
      }
      if (this.rankingTags.length != 0) {
        this.rankingTags = rankingTags;
      }
    },

    // For get point data
    getPointData(value, indexRanking) {
      this.rankingPointCategory.map(ranking => {
        if (ranking.title == value[0]) {
          this.data.rankingOption[indexRanking].point = ranking.point;
        }
      });
    },

    // For remove point data
    removePointData(indexRanking) {
      this.data.rankingOption[indexRanking].point = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.answer {
  &-id {
    padding-top: 0;
    font-size: 14px;
    color: #7e7e7e;
  }

  &-input {
    padding-bottom: 0;
  }
}
</style>
