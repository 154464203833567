<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <b-field>
          <div class="columns is-multiline">
            <div class="column is-3">
              <ValidationProvider
                name="option"
                rules="required"
                v-slot="{ errors }"
              >
                <b-field :type="errors.length > 0 ? 'is-danger' : null">
                  <b-numberinput
                    controls-position="compact"
                    controls-rounded
                    min="0"
                    max="1"
                    type="is-hcc"
                    v-model="data.min"
                  ></b-numberinput>
                </b-field>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="column is-1 to">to</div>
            <div class="column is-3">
              <ValidationProvider
                name="questionMax"
                rules="required"
                v-slot="{ errors }"
              >
                <b-field :type="errors.length > 0 ? 'is-danger' : null">
                  <b-numberinput
                    controls-position="compact"
                    controls-rounded
                    min="2"
                    max="10"
                    type="is-hcc"
                    v-model="data.max"
                  ></b-numberinput>
                </b-field>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </b-field>
        <div class="columns">
          <div class="column is-1">
            {{ data.min }}
          </div>
          <div class="column">
            <ValidationProvider
              name="option1"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input type="text" v-model="data.labelMin"></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
        <div class="columns">
          <div class="column is-1">
            {{ data.max }}
          </div>
          <div class="column">
            <ValidationProvider
              name="option2"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input type="text" v-model="data.labelMax"></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {}
    }
  }
};
</script>
