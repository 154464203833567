<template>
  <div>
    <!-- For title -->
    <h1 class="title">{{ title }}</h1>

    <!-- For button preview -->
    <div class="columns">
      <div class="column is-12">
        <b-button
          type="is-hcc"
          icon-left="eye"
          @click="isModalPreviewActive = true"
        >
          PREVIEW
        </b-button>
      </div>
    </div>

    <!-- For Question -->
    <div class="box question" v-if="title == 'Introduction Test'">
      <div class="columns is-12">
        <div class="column is-2">
          <b>Introduction</b>
          <b-tooltip type="is-danger" label="This field is required">
            <span class="required">*</span>
          </b-tooltip>
        </div>
        <div class="column is-10">
          <ckeditor v-model="data.introduction" :config="editorConfig" />
        </div>
      </div>
    </div>

    <span
      v-for="(section, indexSection) in data.section"
      :key="indexSection"
      class="section"
    >
      <div>
        <div class="box custom-header" type="is-hcc">
          <span class="columns">
            <div class="column is-11 custom-header-label">
              <p>Question Group {{ indexSection + 1 }}</p>
            </div>
            <div class="column is-1 custom-header-label">
              <b-field grouped position="is-right">
                <b-dropdown
                  position="is-bottom-left"
                  append-to-body
                  aria-role="menu"
                >
                  <a
                    class="navbar-item navbar-item-section"
                    slot="trigger"
                    role="button"
                  >
                    <b-icon icon="ellipsis-v"></b-icon>
                  </a>
                  <!-- <b-dropdown-item custom aria-role="menuitem">
                    Duplicate Section
                  </b-dropdown-item> -->
                  <hr class="dropdown-divider" />
                  <b-dropdown-item aria-role="menuitem">
                    <span @click="deleteSectionPopUp(indexSection)">
                      Delete Section
                    </span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-field>
            </div>
          </span>
        </div>
        <template v-if="section.isUseQuestion">
          <div class="box question">
            <div class="columns is-12">
              <!-- For title question -->
              <div class="column is-2">
                <span class="handle">
                  <i class="glyphicon glyphicon-menu-hamburger"></i>
                </span>
                <b>Question</b>
              </div>

              <!-- For type question -->
              <div class="column is-10">
                <div class="columns is-12">
                  <div class="column is-8">
                    <!-- For Text Type -->
                    <template v-if="section.questionType == 'text'">
                      <QuestionTypeText :data="section" />
                    </template>

                    <!-- For Image Type -->
                    <template v-else-if="section.questionType == 'image'">
                      <QuestionTypeImage
                        :data="section"
                        :listEditedPhoto="data.listEditedPhotoQuestion"
                      />
                    </template>

                    <!-- For Video Type -->
                    <template v-else-if="section.questionType == 'video'">
                      <QuestionTypeVideo
                        :data="section"
                        :listEditedPhoto="data.listEditedPhotoQuestion"
                      />
                    </template>

                    <!-- For Free Text Type -->
                    <template v-if="section.questionType == 'free_text'">
                      <QuestionTypeFreeText :data="section" />
                    </template>

                    <!-- For Youtube Link Type -->
                    <template v-else-if="section.questionType == 'youtube'">
                      <QuestionTypeYoutubeLink :data="section" />
                    </template>

                    <!-- For Image And Text Type -->
                    <template v-else-if="section.questionType == 'text_image'">
                      <QuestionTypeTextImage
                        :data="section"
                        :listEditedPhoto="data.listEditedPhotoQuestion"
                      />
                    </template>

                    <!-- For Video And Text Type -->
                    <template v-else-if="section.questionType == 'text_video'">
                      <QuestionTypeTextVideo
                        :data="section"
                        :listEditedPhoto="data.listEditedPhotoQuestion"
                      />
                    </template>

                    <!-- For Youtube Link And Text Type -->
                    <template
                      v-else-if="section.questionType == 'text_youtube'"
                    >
                      <QuestionTypeTextYoutubeLink :data="section" />
                    </template>

                    <!-- For Image And Free Text Type -->
                    <template
                      v-else-if="section.questionType == 'free_text_image'"
                    >
                      <QuestionTypeFreeTextImage
                        :data="section"
                        :listEditedPhoto="data.listEditedPhotoQuestion"
                      />
                    </template>

                    <!-- For Video And Free Text Type -->
                    <template
                      v-else-if="section.questionType == 'free_text_video'"
                    >
                      <QuestionTypeFreeTextVideo
                        :data="section"
                        :listEditedPhoto="data.listEditedPhotoQuestion"
                      />
                    </template>

                    <!-- For Youtube Link And Free Text Type -->
                    <template
                      v-else-if="section.questionType == 'free_text_youtube'"
                    >
                      <QuestionTypeFreeTextYoutubeLink :data="section" />
                    </template>

                    <!-- For type all -->
                    <template v-else-if="section.questionType == 'all'">
                      <QuestionTypeAll
                        :data="section"
                        :listEditedPhoto="data.listEditedPhotoQuestion"
                      />
                    </template>
                  </div>
                  <div class="column is-4">
                    <b-select
                      v-model="section.questionType"
                      @input="changeQuestionSectionType(indexSection)"
                    >
                      <option
                        v-for="(type, t) in questionTypes"
                        :key="t"
                        :value="type.value"
                      >
                        {{ type.title }}
                      </option>
                    </b-select>
                  </div>
                </div>
              </div>
            </div>

            <div class="level question-box-level">
              <div class="level-left"></div>
              <div class="level-right">
                <b-button
                  icon-left="trash"
                  type="is-text"
                  @click="deleteSectionQuestionPopup(indexSection)"
                ></b-button>
              </div>
            </div>
          </div>
        </template>

        <draggable v-model="section.assessment" handle=".handle">
          <div
            v-for="(assessment, indexAssessment) in section.assessment"
            :key="indexAssessment"
            class="box question question-box"
          >
            <div class="columns question-box-icon">
              <span class="column is-12">
                <b-field grouped position="is-centered">
                  <span class="handle">
                    <b-icon type="is-arrow" icon="grip-horizontal"></b-icon>
                  </span>
                </b-field>
              </span>
            </div>

            <div class="columns is-multiline question-box-answer">
              <!-- For Id Question -->
              <div class="column is-12">
                <b>Question ID : {{ assessment.questionId }}</b>
              </div>

              <!-- For title question -->
              <div class="column is-2">
                <span class="handle">
                  <i class="glyphicon glyphicon-menu-hamburger"></i>
                </span>
                <b>#{{ indexAssessment + 1 }}</b>
              </div>

              <!-- For type question -->
              <div class="column is-10">
                <div class="columns is-12">
                  <div class="column is-8">
                    <!-- For Text Type -->
                    <template v-if="assessment.questionType == 'text'">
                      <QuestionTypeText :data="assessment" />
                    </template>

                    <!-- For Image Type -->
                    <template v-else-if="assessment.questionType == 'image'">
                      <QuestionTypeImage
                        :data="assessment"
                        :listEditedPhoto="data.listEditedPhotoQuestion"
                      />
                    </template>

                    <!-- For Video Type -->
                    <template v-else-if="assessment.questionType == 'video'">
                      <QuestionTypeVideo
                        :data="assessment"
                        :listEditedPhoto="data.listEditedPhotoQuestion"
                      />
                    </template>

                    <!-- For Free Text Type -->
                    <template v-if="assessment.questionType == 'free_text'">
                      <QuestionTypeFreeText :data="assessment" />
                    </template>

                    <!-- For Youtube Link Type -->
                    <template v-else-if="assessment.questionType == 'youtube'">
                      <QuestionTypeYoutubeLink :data="assessment" />
                    </template>

                    <!-- For Image And Text Type -->
                    <template
                      v-else-if="assessment.questionType == 'text_image'"
                    >
                      <QuestionTypeTextImage
                        :data="assessment"
                        :listEditedPhoto="data.listEditedPhotoQuestion"
                      />
                    </template>

                    <!-- For Video And Text Type -->
                    <template
                      v-else-if="assessment.questionType == 'text_video'"
                    >
                      <QuestionTypeTextVideo
                        :data="assessment"
                        :listEditedPhoto="data.listEditedPhotoQuestion"
                      />
                    </template>

                    <!-- For Youtube Link And Text Type -->
                    <template
                      v-else-if="assessment.questionType == 'text_youtube'"
                    >
                      <QuestionTypeTextYoutubeLink :data="assessment" />
                    </template>

                    <!-- For Image And Free Text Type -->
                    <template
                      v-else-if="assessment.questionType == 'free_text_image'"
                    >
                      <QuestionTypeFreeTextImage
                        :data="assessment"
                        :listEditedPhoto="data.listEditedPhotoQuestion"
                      />
                    </template>

                    <!-- For Video And Free Text Type -->
                    <template
                      v-else-if="assessment.questionType == 'free_text_video'"
                    >
                      <QuestionTypeFreeTextVideo
                        :data="assessment"
                        :listEditedPhoto="data.listEditedPhotoQuestion"
                      />
                    </template>

                    <!-- For Youtube Link And Free Text Type -->
                    <template
                      v-else-if="assessment.questionType == 'free_text_youtube'"
                    >
                      <QuestionTypeFreeTextYoutubeLink :data="assessment" />
                    </template>

                    <!-- For Youtube Link And Free Text Type -->
                    <template v-else-if="assessment.questionType == 'all'">
                      <QuestionTypeAll
                        :data="assessment"
                        :listEditedPhoto="data.listEditedPhotoQuestion"
                      />
                    </template>
                  </div>
                  <div class="column is-4">
                    <b-select
                      v-model="assessment.questionType"
                      @input="
                        changeQuestionAssessmentType(
                          indexSection,
                          indexAssessment
                        )
                      "
                    >
                      <option
                        v-for="(type, t) in questionTypes"
                        :key="t"
                        :value="type.value"
                      >
                        {{ type.title }}
                      </option>
                    </b-select>
                  </div>
                </div>
              </div>
            </div>

            <!-- For type question -->
            <div
              class="columns is-12 question-box-answer"
              v-if="
                assessment.type == 'radio-button' ||
                  assessment.type == 'checkbox'
              "
            >
              <div class="column is-2">
                <b>Type</b>
              </div>
              <div class="column is-10">
                <b-select v-model="assessment.optionRules">
                  <option value="Regular Button">Regular Button</option>
                  <option value="Text Inside Button">Text Inside Button</option>
                </b-select>
              </div>
            </div>

            <!-- For answer quetion -->
            <div
              class="columns question-box-answer"
              v-if="assessment.type != 'question'"
            >
              <div class="column is-2">
                <b>Answer</b>
              </div>

              <div class="column is-10">
                <!-- *Note : textarea dan textfield componentnya ditukar sementara -->
                <!-- For Type Textfield -->
                <template v-if="assessment.type == 'textfield'">
                  <AnswerTypeTextarea
                    :data="assessment"
                    :title="title"
                    :indexSection="indexSection"
                    :indexAssessment="indexAssessment"
                  />
                </template>

                <!-- For Type Textarea -->
                <template v-else-if="assessment.type == 'textarea'">
                  <AnswerTypeTextfield
                    :data="assessment"
                    :title="title"
                    :indexSection="indexSection"
                    :indexAssessment="indexAssessment"
                  />
                </template>

                <!-- For Type Multiple Choice -->
                <template v-else-if="assessment.type == 'radio-button'">
                  <AnswerTypeRadioButton
                    :data="assessment"
                    :title="title"
                    :rulesData="rulesData"
                    :listEditedPhoto="data.listEditedPhotoOption"
                    :indexSection="indexSection"
                    :indexAssessment="indexAssessment"
                  />
                </template>

                <!-- For Type Checkbox -->
                <template v-else-if="assessment.type == 'checkbox'">
                  <AnswerTypeCheckbox
                    :data="assessment"
                    :title="title"
                    :rulesData="rulesData"
                    :listEditedPhoto="data.listEditedPhotoOption"
                    :indexSection="indexSection"
                    :indexAssessment="indexAssessment"
                  />
                </template>

                <!-- For Type Linear Scale -->
                <template v-else-if="assessment.type == 'linear-scale'">
                  <AnswerTypeLinearScale
                    :data="assessment"
                    :indexSection="indexSection"
                    :indexAssessment="indexAssessment"
                  />
                </template>

                <template v-else-if="assessment.type == 'ranking'">
                  <AnswerTypeRanking
                    :data="assessment"
                    :rankingPointCategory="rulesData.rankingPointCategory"
                    :indexSection="indexSection"
                    :indexAssessment="indexAssessment"
                  />
                </template>

                <!-- For Type Linear Scale -->
                <template v-else-if="assessment.type == 'subsection'">
                  <AnswerTypeSubsection :data="assessment" />
                </template>
              </div>
            </div>

            <div class="level question-box-level">
              <div class="level-left">
                <span v-if="section.assessment.length - 1 == indexAssessment">
                  <b-field>
                    <b-button
                      icon-left="plus-circle"
                      type="is-text"
                      class="add-question"
                      title="Add Question"
                      @click="modalQuestion(indexSection)"
                    >
                      <span class="pull-left">Add Question</span>
                    </b-button>
                  </b-field>
                </span>
              </div>
              <div class="level-right">
                <b-button
                  icon-left="copy"
                  type="is-text"
                  title="Copy Question"
                  @click="copyPopup(indexAssessment, indexSection)"
                ></b-button>
                <b-button
                  icon-left="trash"
                  type="is-text"
                  title="Delete Question"
                  @click="deletePopup(indexAssessment, indexSection)"
                ></b-button>
                <div class="required-button">
                  <b-switch
                    type="is-hcc"
                    size="is-small"
                    v-model="assessment.require"
                  >
                    Required
                  </b-switch>
                </div>
              </div>
            </div>
          </div>
        </draggable>

        <br />

        <!-- Modal For Question -->
        <div class="columns">
          <div class="column is-12">
            <div
              class="columns is-multiline"
              v-if="section.assessment.length == 0"
            >
              <div class="column is-6 add-question-outside">
                <b-field>
                  <b-button
                    icon-left="plus-circle"
                    class="add-question"
                    title="Add Question"
                    @click="modalQuestion(indexSection)"
                  >
                    <span class="pull-left">Add question</span>
                  </b-button>
                </b-field>
              </div>
            </div>
            <!--   <div class="column is-6">
                <b-field grouped position="is-right">
                  <b-button
                    icon-left="trash"
                    class="deleteAssessment"
                    @click="deleteSectionPopUp(indexSection)"
                  ></b-button>
                </b-field>
              </div>
            </div> -->

            <!-- For Modal Assessment Select -->
            <b-modal :active.sync="isImageModalActive" :width="300">
              <div class="box">
                <div class="columns is-multiline">
                  <div
                    class="column is-12 border-bottom"
                    @click="addAssessment('radio-button')"
                    v-if="rulesData.typePoint.includes('radio-button')"
                  >
                    <img
                      src="../../../assets/image/radio-button.png"
                      width="20px;"
                    />
                    Radio Button
                  </div>
                  <div
                    class="column is-12 border-bottom"
                    @click="addAssessment('checkbox')"
                    v-if="rulesData.typePoint.includes('checkbox')"
                  >
                    <img
                      src="../../../assets/image/checkbox.png"
                      width="20px;"
                    />
                    Checkbox
                  </div>
                  <div
                    class="column is-12 border-bottom"
                    @click="addAssessment('textfield')"
                    v-if="rulesData.typePoint.includes('textfield')"
                  >
                    <img
                      src="../../../assets/image/text-field.png"
                      width="20px;"
                    />
                    Textfield
                  </div>
                  <div
                    class="column is-12 border-bottom"
                    @click="addAssessment('textarea')"
                    v-if="rulesData.typePoint.includes('textarea')"
                  >
                    <img
                      src="../../../assets/image/textarea.png"
                      width="20px;"
                    />
                    Textarea
                  </div>
                  <div
                    class="column is-12 border-bottom"
                    @click="addAssessment('linear-scale')"
                    v-if="rulesData.typePoint.includes('linear-scale')"
                  >
                    <img
                      src="../../../assets/image/linear-scale.png"
                      width="20px;"
                    />
                    Linear scale
                  </div>
                  <div
                    class="column is-12 border-bottom"
                    @click="addAssessment('ranking')"
                    v-if="rulesData.typePoint.includes('ranking')"
                  >
                    <img src="../../../assets/image/trophy.png" width="20px;" />
                    Ranking
                  </div>
                  <div
                    class="column is-12 border-bottom"
                    @click="addAssessment('question')"
                    v-if="
                      rulesData.typePoint.includes('question') &&
                        !section.isUseQuestion
                    "
                  >
                    <img
                      src="../../../assets/image/question.png"
                      width="20px;"
                    />
                    Question
                  </div>
                  <!-- <div
                    class="column is-12 border-bottom"
                    @click="addAssessment('subsection')"
                    v-if="typeStep == 'questionTest'"
                  >
                    <img
                      src="../../../assets/image/subsection.png"
                      width="20px;"
                    />
                    Subsection
                  </div>-->
                </div>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
    </span>

    <div class="columns">
      <div class="column is-12">
        <b-button
          type="is-hcc"
          outlined
          @click="addSection()"
          title="Add Question Group"
        >
          <span class="pull-left">Add Question Group</span>
        </b-button>
      </div>
    </div>

    <!-- For Modal Assessment Preview -->
    <b-modal
      :active.sync="isModalPreviewActive"
      has-modal-card
      full-screen
      scroll="keep"
    >
      <AssessmentPreview
        :data="data"
        :rulesData="rulesData"
        :type="'form'"
        :title="title"
      />
    </b-modal>
  </div>
</template>

<script>
// For Package
import draggable from 'vuedraggable'

// For Question Vue Component
import QuestionTypeText from './question-type/QuestionTypeText'
import QuestionTypeImage from './question-type/QuestionTypeImage'
import QuestionTypeVideo from './question-type/QuestionTypeVideo'
import QuestionTypeYoutubeLink from './question-type/QuestionTypeYoutubeLink'
import QuestionTypeFreeText from './question-type/QuestionTypeFreeText'
import QuestionTypeTextImage from './question-type/QuestionTypeTextImage'
import QuestionTypeTextVideo from './question-type/QuestionTypeTextVideo'
import QuestionTypeTextYoutubeLink from './question-type/QuestionTypeTextYoutubeLink'
import QuestionTypeFreeTextImage from './question-type/QuestionTypeFreeTextImage'
import QuestionTypeFreeTextVideo from './question-type/QuestionTypeFreeTextVideo'
import QuestionTypeFreeTextYoutubeLink from './question-type/QuestionTypeFreeTextYoutubeLink'
import QuestionTypeAll from './question-type/QuestionTypeAll'

// For Answer Vue Component
import AnswerTypeTextfield from './answer-type/AnswerTypeTextfield'
import AnswerTypeTextarea from './answer-type/AnswerTypeTextarea'
import AnswerTypeRadioButton from './answer-type/AnswerTypeRadioButton'
import AnswerTypeCheckbox from './answer-type/AnswerTypeCheckbox'
import AnswerTypeLinearScale from './answer-type/AnswerTypeLinearScale'
import AnswerTypeRanking from './answer-type/AnswerTypeRanking'
import AnswerTypeSubsection from './answer-type/AnswerTypeSubsection'

// For Preview Vude Component
import AssessmentPreview from '../assessment-preview/AssessmentPreview'

export default {
  components: {
    // Using Package
    draggable,

    // Using Question Component
    QuestionTypeText,
    QuestionTypeImage,
    QuestionTypeVideo,
    QuestionTypeYoutubeLink,
    QuestionTypeFreeText,
    QuestionTypeTextImage,
    QuestionTypeTextVideo,
    QuestionTypeTextYoutubeLink,
    QuestionTypeFreeTextImage,
    QuestionTypeFreeTextVideo,
    QuestionTypeFreeTextYoutubeLink,
    QuestionTypeAll,

    // Using Answer Component
    AnswerTypeTextfield,
    AnswerTypeTextarea,
    AnswerTypeRadioButton,
    AnswerTypeCheckbox,
    AnswerTypeLinearScale,
    AnswerTypeRanking,
    AnswerTypeSubsection,

    // Using Preview Component
    AssessmentPreview,
  },
  props: {
    data: {
      default: () => {},
    },
    rulesData: {
      default: () => {},
    },
    title: {
      default: () => {},
    },
  },
  data() {
    return {
      editorConfig: {
        // The configuration of the editor.
        extraAllowedContent: 'div[class]',
        allowedContent: true,
        // filebrowserUploadMethod: "form",
        // filebrowserUploadUrl: process.env.VUE_APP_BACKEND_URL + "/api/ckeditor/upload-image",
        extraPlugins: 'justify,font',
      },
      isImageModalActive: false,
      isModalPreviewActive: false,
      sectionNumber: 2,
      questionTypes: [
        {
          title: 'Text',
          value: 'text',
        },
        {
          title: 'Image',
          value: 'image',
        },
        {
          title: 'Video',
          value: 'video',
        },
        {
          title: 'Free Text',
          value: 'free_text',
        },
        {
          title: 'Youtube Link',
          value: 'youtube',
        },
        {
          title: 'Text + Image',
          value: 'text_image',
        },
        {
          title: 'Text + Video',
          value: 'text_video',
        },
        {
          title: 'Text + Youtube Link',
          value: 'text_youtube',
        },
        {
          title: 'Free Text + Image',
          value: 'free_text_image',
        },
        {
          title: 'Free Text + Video',
          value: 'free_text_video',
        },
        {
          title: 'All',
          value: 'all',
        },
        // {
        //   title: "Free Text + Youtube Link",
        //   value: "free_text_youtube"
        // }
      ],
    }
  },
  mounted() {
    if (this.$route.name == 'editAssessmentSection') {
      let lastDataOfSection = this.data.section.length - 1

      this.sectionNumber =
        this.data.section[lastDataOfSection].sectionNumber + 1
    }
  },
  methods: {
    // For success alert
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },

    // For error/danger alert
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },

    // For add section
    addSection() {
      let section = {
        id: null,
        groupNumber: this.sectionNumber,
        groupId:
          Math.floor(10000 + Math.random() * 90000) +
          Math.round(new Date().getTime() / 1000),
        isUseQuestion: false,
        question: '',
        questionType: 'text',
        questionYoutubeLink: '',
        questionFreeText: '',
        questionFileType: 'image',
        questionVideoPlayed: 0,
        questionVideoLimit: false,
        questionFile: [
          {
            file: {
              name: null,
            },
            fileType: '',
            fileNumber: 1,
            fileId:
              Math.floor(10000 + Math.random() * 90000) +
              Math.round(new Date().getTime() / 1000),
            url: null,
          },
        ],
        assessment: [],
      }

      this.data.section.push(section)
      this.sectionNumber += 1
    },

    // For Add Assessment
    addAssessment(val) {
      if (val == 'question') {
        this.data.section[this.currentSection].isUseQuestion = true
      } else {
        if (this.data.child != true) {
          let lastData = this.data.assessment.length

          let assessment = {
            id: null,
            type: val,
            question: '',
            questionId:
              Math.floor(10000 + Math.random() * 90000) +
              Math.round(new Date().getTime() / 1000),
            textRules: {
              type: 'text',
              typeLength: 'character',
              min: 0,
              max: 0,
            },
            optionRules: 'Regular Button',
            questionType: 'text',
            questionYoutubeLink: '',
            questionFreeText: '',
            questionNumber: lastData + 1,
            questionFileType: 'image',
            questionVideoPlayed: 0,
            questionVideoLimit: false,
            questionFile: [
              {
                file: {
                  name: null,
                },
                fileNumber: 1,
                fileId:
                  Math.floor(10000 + Math.random() * 90000) +
                  Math.round(new Date().getTime() / 1000),
                url: null,
                status: '',
              },
            ],
            option: [
              {
                title: '',
                type: 'text',
                optionNumber: 1,
                optionId:
                  Math.floor(10000 + Math.random() * 90000) +
                  Math.round(new Date().getTime() / 1000) +
                  1,
                point: 0,
                coding: '',
                file: {
                  name: null,
                },
                fileUrl: null,
                fileStatus: '',
              },
              {
                title: '',
                type: 'text',
                optionNumber: 2,
                optionId:
                  Math.floor(10000 + Math.random() * 90000) +
                  Math.round(new Date().getTime() / 1000) +
                  2,
                point: 0,
                coding: '',
                file: {
                  name: null,
                },
                fileUrl: null,
                fileStatus: '',
              },
              {
                title: '',
                type: 'text',
                optionId:
                  Math.floor(10000 + Math.random() * 90000) +
                  Math.round(new Date().getTime() / 1000) +
                  3,
                optionNumber: 3,
                point: 0,
                coding: '',
                file: {
                  name: null,
                },
                fileUrl: null,
                fileStatus: '',
              },
            ],
            rankingOption: [
              {
                rankingId:
                  Math.floor(10000 + Math.random() * 90000) +
                  Math.round(new Date().getTime() / 1000) +
                  1283,
                option: '',
                rulesTitle: [],
                point: 0,
              },
              {
                rankingId:
                  Math.floor(10000 + Math.random() * 90000) +
                  Math.round(new Date().getTime() / 1000) +
                  5871,
                option: '',
                rulesTitle: [],
                point: 0,
              },
              {
                rankingId:
                  Math.floor(10000 + Math.random() * 90000) +
                  Math.round(new Date().getTime() / 1000) +
                  4801,
                option: '',
                rulesTitle: [],
                point: 0,
              },
            ],
            min: 1,
            max: 10,
            labelMin: '',
            labelMax: '',
            answer: '',
            answerOption: [],
            answerRanking: [],
            textTrueAnswer: '',
            textFalseAnswer: '',
            require: false,
            section: true,
            status: 'new',
          }

          this.data.section[this.currentSection].assessment.push(assessment)
          // if (val == "subsection") {
          //   this.data.child = true;
          //   this.addSubSection(this.currentSection);
          // }
        } else {
          this.danger('Only 1 subsection allowed per section')
        }
      }
      this.isImageModalActive = false
    },

    copyPopup(indexAssessment, indexSection) {
      this.$buefy.dialog.confirm({
        title: 'Copy Question',
        message: `Are you sure want to copy this question?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, copy it!',
        type: 'is-hcc',
        onConfirm: () => this.copyAssessment(indexAssessment, indexSection),
      })
    },

    copyAssessment(indexAssessment, indexSection) {
      let data = JSON.parse(
        JSON.stringify(
          this.data.section[indexSection].assessment[indexAssessment]
        )
      )

      if (this.$route.name == 'editAssessmentSection') {
        let questionId =
          Math.floor(10000 + Math.random() * 90000) +
          Math.round(new Date().getTime() / 1000) +
          2333

        let questionFile = []
        if (data.status != 'new') {
          data.questionFile.map((file, f) => {
            questionFile.push({
              file: file.file,
              fileNumber: file.fileNumber,
              fileId:
                Math.floor(10000 + Math.random() * 90000) +
                Math.round(new Date().getTime() / 1000) +
                f +
                2312,
              url: file.url,
              status: 'copy',
            })
          })
        } else {
          let questionFileData = this.data.section[indexSection].assessment[
            indexAssessment
          ].questionFile

          questionFileData.map((file, f) => {
            questionFile.push({
              ...file,
              fileId:
                Math.floor(10000 + Math.random() * 90000) +
                Math.round(new Date().getTime() / 1000) +
                f +
                2312,
            })
          })

          questionFileData.map(file => {
            this.data.listEditedPhotoQuestion.push({
              type: 'create',
              questionId: questionId,
              fileData: file.file,
              filePath: '',
              fileId: file.fileId,
              fileNumber: file.fileNumber,
            })
          })
        }

        let optionData = []
        if (data.status != 'new') {
          data.option.map((option, o) => {
            optionData.push({
              title: option.title,
              type: option.type,
              optionId:
                Math.floor(10000 + Math.random() * 90000) +
                Math.round(new Date().getTime() / 1000) +
                o +
                1241,
              optionNumber: option.optionNumber,
              point: option.point,
              coding: option.coding,
              file: option.file,
              fileUrl: option.fileUrl,
              fileStatus: 'copy',
            })
          })
        } else {
          let optionDataListSection = this.data.section[indexSection]
            .assessment[indexAssessment].option

          optionDataListSection.map((option, o) => {
            let optionId =
              Math.floor(10000 + Math.random() * 90000) +
              Math.round(new Date().getTime() / 1000) +
              o +
              1246

            optionData.push({
              ...option,
              optionId: optionId,
            })

            this.data.listEditedPhotoOption.push({
              type: 'create',
              questionId: questionId,
              fileData: option.file,
              filePath: '',
              optionId: optionId,
              optionNumber: option.optionNumber,
            })
          })
        }

        this.data.section[indexSection].assessment.push({
          id: null,
          type: data.type,
          question: data.question,
          questionId: questionId,
          textRules: data.textRules,
          optionRules: data.optionRules,
          questionType: data.questionType,
          questionYoutubeLink: data.questionYoutubeLink,
          questionFreeText: data.questionFreeText,
          questionNumber: data.questionNumber,
          questionFile: questionFile,
          option: optionData,
          rankingOption: data.rankingOption,
          min: data.min,
          max: data.max,
          labelMin: data.labelMin,
          labelMax: data.labelMax,
          answer: data.answer,
          answerOption: data.answerOption,
          answerRanking: data.answerRanking,
          textTrueAnswer: data.textTrueAnswer,
          textFalseAnswer: data.textFalseAnswer,
          require: data.require,
          section: data.section,
          status: data.status,
        })
      } else if (this.$route.name == 'addAssessmentSection') {
        let questionId =
          Math.floor(10000 + Math.random() * 90000) +
          Math.round(new Date().getTime() / 1000) +
          3844

        let questionFileData = this.data.section[indexSection].assessment[
          indexAssessment
        ].questionFile

        let questionFile = []
        questionFileData.map((file, f) => {
          questionFile.push({
            ...file,
            fileId:
              Math.floor(10000 + Math.random() * 90000) +
              Math.round(new Date().getTime() / 1000) +
              f +
              2312,
          })
        })

        let optionDataListSection = this.data.section[indexSection].assessment[
          indexAssessment
        ].option

        let optionData = []
        optionDataListSection.map((option, o) => {
          let optionId =
            Math.floor(10000 + Math.random() * 90000) +
            Math.round(new Date().getTime() / 1000) +
            o +
            1246

          optionData.push({
            ...option,
            optionId: optionId,
          })
        })

        this.data.section[indexSection].assessment.push({
          id: null,
          type: data.type,
          question: data.question,
          questionId: questionId,
          textRules: data.textRules,
          optionRules: data.optionRules,
          questionType: data.questionType,
          questionYoutubeLink: data.questionYoutubeLink,
          questionFreeText: data.questionFreeText,
          questionNumber: data.questionNumber,
          questionFile: questionFile,
          option: optionData,
          rankingOption: data.rankingOption,
          min: data.min,
          max: data.max,
          labelMin: data.labelMin,
          labelMax: data.labelMax,
          answer: data.answer,
          answerOption: data.answerOption,
          answerRanking: data.answerRanking,
          textTrueAnswer: data.textTrueAnswer,
          textFalseAnswer: data.textFalseAnswer,
          require: data.require,
          section: data.section,
          status: data.status,
        })
      }
    },

    // For delete section question popup
    deleteSectionQuestionPopup(indexSection) {
      this.$buefy.dialog.confirm({
        title: 'Delete Question',
        message: `Are you sure want to delete this question?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, delete it!',
        type: 'is-danger',
        onConfirm: () => this.deleteSectionQuestion(indexSection),
      })
    },

    // For delete section question
    deleteSectionQuestion(indexSection) {
      this.data.section[indexSection].isUseQuestion = false
    },

    // For delete popup
    deletePopup(indexAssessment, indexSection) {
      this.$buefy.dialog.confirm({
        title: 'Delete Question',
        message: `Are you sure want to delete this question?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, delete it!',
        type: 'is-danger',
        onConfirm: () => this.deleteAssessment(indexAssessment, indexSection),
      })
    },

    // For delete assessment
    deleteAssessment(indexAssessment, indexSection) {
      let isListed = false
      if (this.$route.name == 'editAssessmentSection') {
        if (
          this.data.section[indexSection].assessment[indexAssessment]
            .questionType != 'text' &&
          this.data.section[indexSection].assessment[indexAssessment]
            .questionType != 'youtube' &&
          this.data.section[indexSection].assessment[indexAssessment]
            .questionType != 'free_text' &&
          this.data.section[indexSection].assessment[indexAssessment]
            .questionType != 'text_youtube'
        ) {
          isListed = true
          this.data.listEditedPhotoQuestion.push({
            type: 'delete-question',
            questionId: this.data.section[indexSection].assessment[
              indexAssessment
            ].questionId,
            fileData: '',
            filePath: '',
            fileNumber: '',
          })
        }

        if (this.rulesData.questionType == 'Multiple Choice' && !isListed) {
          if (
            this.data.section[indexSection].assessment[indexAssessment].type ==
              'checkbox' ||
            this.data.section[indexSection].assessment[indexAssessment].type ==
              'radio-button'
          ) {
            let isOptionImage = false
            this.data.section[indexSection].assessment[
              indexAssessment
            ].option.map(option => {
              if (option.type == 'image') {
                isOptionImage = true
              }
            })

            if (isOptionImage) {
              this.data.listEditedPhotoQuestion.push({
                type: 'delete-question',
                questionId: this.data.section[indexSection].assessment[
                  indexAssessment
                ].questionId,
                fileData: '',
                filePath: '',
                fileNumber: '',
              })
            }
          }
        }
      }

      if (this.$route.name == 'editAssessmentSection') {
        if (
          this.data.section[indexSection].assessment[indexAssessment].id != null
        ) {
          this.data.deletedQuestion.push(
            this.data.section[indexSection].assessment[indexAssessment].id
          )
        }
      }

      this.data.section[indexSection].assessment.splice(indexAssessment, 1)

      this.success('Successfully delete assessment')
    },

    // For delete section popup
    deleteSectionPopUp(index) {
      this.$buefy.dialog.confirm({
        title: 'Delete Section',
        message: `Are you sure want to delete this section?`,
        cancelText: 'No, cancel it!',
        confirmText: 'Yes, delete it!',
        type: 'is-danger',
        onConfirm: () => this.removeSection(index),
      })
    },

    // For remove section
    removeSection(index) {
      let isListed = false
      if (this.$route.name == 'editAssessmentSection') {
        this.data.section[index].assessment.map(assessment => {
          if (
            assessment.questionType != 'text' &&
            assessment.questionType != 'youtube' &&
            assessment.questionType != 'free_text' &&
            assessment.questionType != 'text_youtube'
          ) {
            this.data.listEditedPhotoQuestion.push({
              type: 'delete-question',
              questionId: assessment.questionId,
              fileData: '',
              filePath: '',
              fileNumber: '',
            })
          }

          if (this.rulesData.questionType == 'Multiple Choice' && !isListed) {
            if (
              assessment.type == 'checkbox' ||
              assessment.type == 'radio-button'
            ) {
              let isOptionImage = false
              assessment.option.map(option => {
                if (option.type == 'image') {
                  isOptionImage = true
                }
              })

              if (isOptionImage) {
                this.data.listEditedPhotoQuestion.push({
                  type: 'delete-question',
                  questionId: assessment.questionId,
                  fileData: '',
                  filePath: '',
                  fileNumber: '',
                })
              }
            }
          }
        })
      }

      // if (this.rulesData.questionType == "Multiple Choice" && !isListed) {
      //   if (
      //     this.data.section[indexSection].assessment[indexAssessment].type ==
      //       "checkbox" ||
      //       this.data.section[indexSection].assessment[indexAssessment].type ==
      //         "radio-button"
      //   ) {
      //     let isOptionImage = false;
      //     this.data.section[indexSection].assessment[
      //       indexAssessment
      //     ].option.map(option => {
      //       if (option.type == "image") {
      //         isOptionImage = true;
      //       }
      //     });

      //     if (isOptionImage) {
      //       this.data.listEditedPhotoQuestion.push({
      //         type: "delete-question",
      //         questionId: this.data.section[indexSection].assessment[
      //           indexAssessment
      //         ].questionId,
      //         fileData: "",
      //         filePath: "",
      //         fileNumber: ""
      //       });
      //     }
      //   }
      // }

      if (this.$route.name == 'editAssessmentSection') {
        if (this.data.section[index].id != null) {
          this.data.deletedSection.push(this.data.section[index].id)
        }
      }

      if (this.data.section.length <= 1) {
        this.danger('Cannot delete this section')
      } else {
        if (this.data.section[index].child == true) {
          if (this.data.section.length == 3) {
            this.danger('Cannot delete this section')
          } else {
            this.success('Successfully remove a section')
            this.data.section.splice(index + 1, 2)
            this.data.section.splice(index, 1)
          }
        } else {
          this.success('Successfully removed a section')
          this.data.section.splice(index, 1)
        }
      }
    },

    // For Modal of Question
    modalQuestion(index) {
      this.isImageModalActive = true
      this.currentSection = index
    },

    // for upload image to blob
    uploadImageToBlob(image, indexFile, indexAssessment) {
      let blobUrl = URL.createObjectURL(image)

      this.data.assessment[indexAssessment].questionFile[
        indexFile
      ].url = blobUrl
    },

    // For Add Image Array
    addMoreImage(indexAssessment) {
      this.data.assessment[indexAssessment].questionFile.push({
        file: {
          name: null,
        },
        url: null,
      })
    },

    // For deleting Image
    deleteImage(indexFile, indexAssessment) {
      this.data.assessment[indexAssessment].questionFile.splice(indexFile, 1)
    },

    // If the question type is change
    changeQuestionSectionType(indexSection) {
      this.data.section[indexSection].questionFile = [
        {
          file: {
            name: null,
          },
          url: null,
        },
      ]
    },

    // If the question type is change
    changeQuestionAssessmentType(indexSection, indexAssessment) {
      this.data.section[indexSection].assessment[
        indexAssessment
      ].questionFile = [
        {
          file: {
            name: null,
          },
          url: null,
        },
      ]
    },
  },
}
</script>
<style lang="scss">
.required-button {
  border-left: 0.5px solid #dddddd;
  padding-left: 10px;
}
.button-add-question {
  margin-top: 1%;
}

.question-box {
  padding: 0;

  &-icon {
    padding-top: 1%;

    .column {
      padding-bottom: 0;
    }
  }

  &-answer {
    padding: 0% 0.5% 1% 3%;
  }

  &-level {
    padding: 1% 1% 1% 3%;
    border-top: 0.5px solid #dddddd;
  }
}

.message-body {
  padding: 1.25em 0 1.5em !important;
}

.custom-header {
  padding: 1% 1% 1% 1%;
  background-color: #00a3a3;
  color: white;

  &-label {
    // padding-top:1.5%;
    margin: auto;
    color: white;

    p {
      font-weight: 700;
      padding-left: 2%;
    }
  }
}

.navbar-item-section {
  color: white;
}

.navbar-item-section:hover {
  background-color: #00a3a3 !important;
  color: white !important;
}
.navbar-item-section:active {
  color: white !important;
}

.add-question {
  background-color: white;
  color: #00a3a3 !important;
  text-decoration: none !important;
  padding-left: 0 !important;
  border: none !important;
}

.add-question:hover {
  background-color: white !important;
  border: none !important;
}

.add-question:active {
  background-color: white !important;
  border: none !important;
}

@media screen and (min-width: 1408px) {
  .add-question-outside {
    padding-left: 3%;
  }
}

@media screen and (min-width: 1216px) {
  .add-question-outside {
    padding-left: 4%;
  }
}
</style>
