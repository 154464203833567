var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns is-multiline"},[_vm._l((_vm.data.option),function(option,indexOption){return _c('div',{key:indexOption,staticClass:"column is-12"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-6 answer-input"},[(option.type == 'text')?[_c('ValidationProvider',{attrs:{"vid":("checkbox" + _vm.indexSection + _vm.indexAssessment + indexOption),"name":"option","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-checkbox',{attrs:{"type":"is-hcc","disabled":""}}),_c('b-input',{attrs:{"expanded":"","type":"text","name":"uploadImage"},model:{value:(option.title),callback:function ($$v) {_vm.$set(option, "title", $$v)},expression:"option.title"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})]:(option.type == 'image')?[_c('ValidationProvider',{attrs:{"vid":("multipleimage" + _vm.indexSection + _vm.indexAssessment + indexOption),"name":"option","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"file",attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-checkbox',{attrs:{"type":"is-hcc","disabled":""}}),_c('span',{staticClass:"file-name"},[_vm._v(_vm._s(option.file.name))]),_c('b-upload',{attrs:{"accept":"image/*","name":"uploadImage"},on:{"input":function($event){return _vm.uploadFileToBlob($event, indexOption)}},model:{value:(option.file),callback:function ($$v) {_vm.$set(option, "file", $$v)},expression:"option.file"}},[_c('a',{staticClass:"button is-hcc"},[_c('b-icon',{attrs:{"icon":"upload"}}),_c('span',[_vm._v("Upload")])],1)])],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))]),(option.fileUrl == null)?_c('img',{attrs:{"src":require("../../../../assets/logo.png"),"alt":"Image"}}):_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getUrlFile(option.fileUrl)),expression:"getUrlFile(option.fileUrl)"}],attrs:{"alt":"Image"}})]}}],null,true)})]:_vm._e()],2),_c('div',{staticClass:"column is-2 answer-input"},[(_vm.rulesData.multipleChoiceType == 'Tally')?[_c('ValidationProvider',{attrs:{"vid":("multiplepoint" + _vm.indexSection + _vm.indexAssessment + indexOption),"name":"option","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-numberinput',{attrs:{"controls-position":"compact","controls-rounded":"","type":"is-hcc"},model:{value:(option.point),callback:function ($$v) {_vm.$set(option, "point", $$v)},expression:"option.point"}})],1)]}}],null,true)})]:(_vm.rulesData.multipleChoiceType == 'Pass/Fail')?[_c('ValidationProvider',{attrs:{"vid":("multiplepoint" + _vm.indexSection + _vm.indexAssessment + indexOption),"name":"option","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-switch',{attrs:{"value":_vm.data.answerOption.includes(option.optionId),"type":"is-hcc","disabled":_vm.isDisabledSwitch &&
                      !_vm.data.answerOption.includes(option.optionId)},on:{"input":function($event){return _vm.changeOptionTrueAnswer(option.optionId)}}})],1)]}}],null,true)})]:(_vm.rulesData.multipleChoiceType == 'Coding')?[_c('ValidationProvider',{attrs:{"vid":("multiplecoding" + _vm.indexSection + _vm.indexAssessment + indexOption),"name":"option","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"controls-position":"compact","controls-rounded":"","type":"is-hcc"},model:{value:(option.coding),callback:function ($$v) {_vm.$set(option, "coding", $$v)},expression:"option.coding"}})],1)]}}],null,true)})]:_vm._e()],2),_c('div',{staticClass:"column is-2 answer-input"},[_c('b-select',{model:{value:(option.type),callback:function ($$v) {_vm.$set(option, "type", $$v)},expression:"option.type"}},_vm._l((_vm.optionTypeData),function(type,o){return _c('option',{key:o,domProps:{"value":type.value}},[_vm._v(" "+_vm._s(type.title)+" ")])}),0)],1),_c('div',{staticClass:"column is-2 answer-input"},[_c('b-button',{attrs:{"icon-left":"times"},on:{"click":function($event){return _vm.deleteOption(indexOption)}}})],1),_c('div',{staticClass:"column is-12 answer-id"},[_c('b',[_vm._v("Answer ID : "+_vm._s(option.optionId))])])])])}),_c('div',{staticClass:"column is-12"},[_c('b-field',[_c('b-checkbox',{attrs:{"type":"is-hcc","disabled":""}}),_c('b-button',{staticClass:"addOption",attrs:{"type":"is-hcc"},on:{"click":function($event){return _vm.addOption()}}},[_vm._v(" ADD OPTION ")])],1)],1),(
        _vm.title == 'Introduction Test' &&
          _vm.rulesData.multipleChoiceType == 'Pass/Fail'
      )?_c('div',{staticClass:"column is-12"},[_c('strong',[_vm._v("True Answer")]),_c('br'),_c('ckeditor',{attrs:{"config":_vm.editorConfig},model:{value:(_vm.data.textTrueAnswer),callback:function ($$v) {_vm.$set(_vm.data, "textTrueAnswer", $$v)},expression:"data.textTrueAnswer"}}),_c('br'),_c('br'),_c('strong',[_vm._v("False Answer")]),_c('br'),_c('ckeditor',{attrs:{"config":_vm.editorConfig},model:{value:(_vm.data.textFalseAnswer),callback:function ($$v) {_vm.$set(_vm.data, "textFalseAnswer", $$v)},expression:"data.textFalseAnswer"}})],1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }