<template>
  <div>
    <div class="columns">
      <div class="column is-12">
        <b>Youtube Link</b>
      </div>
    </div>
    <ValidationProvider
      :vid="`questionyoutubelink`"
      name="Question Youtube Link"
      rules="required"
      v-slot="{ errors }"
    >
      <b-field :type="errors.length > 0 ? 'is-danger' : null">
        <b-input type="textarea" v-model="data.questionYoutubeLink"></b-input>
      </b-field>
      <span class="required">{{ errors[0] }}</span>
    </ValidationProvider>

    <br />

    <div v-html="data.questionYoutubeLink"></div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {}
    }
  }
};
</script>
