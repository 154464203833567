<template>
  <div>
    <ValidationProvider
      :vid="`questiontext`"
      name="Question Text"
      rules="required"
      v-slot="{ errors }"
    >
      <b-field :type="errors.length > 0 ? 'is-danger' : null">
        <b-input type="text" v-model="data.question"></b-input>
      </b-field>
      <span class="required">{{ errors[0] }}</span>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {}
    }
  }
};
</script>
