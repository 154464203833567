<template>
  <div>
    <!-- For Text -->
    <ValidationProvider
      :vid="`questiontext`"
      name="Question Text"
      rules="required"
      v-slot="{ errors }"
    >
      <b-field :type="errors.length > 0 ? 'is-danger' : null">
        <b-input type="text" v-model="data.question"></b-input>
      </b-field>
      <span class="required">{{ errors[0] }}</span>
    </ValidationProvider>

    <br />

    <!-- For Image -->
    <!-- For Title Image Field -->
    <div class="columns">
      <div class="column is-12">
        <b>Add Image</b>
      </div>
    </div>

    <!-- For uploading image -->
    <div v-for="(file, f) in data.questionFile" :key="f">
      <div class="columns">
        <div class="column is-10">
          <b-field class="file">
            <span class="file-name">{{ file.file.name }}</span>
            <b-upload
              v-model="file.file"
              @input="uploadFileToBlob($event, f)"
              accept="image/*"
              type="is-hcc"
            >
              <a class="button is-hcc">
                <b-icon icon="upload"></b-icon>
                <span>Upload</span>
              </a>
            </b-upload>
          </b-field>
        </div>

        <!-- For button delete file from array -->
        <div
          class="column is-2"
          style="padding-top: 18px"
          v-if="data.questionFile.length > 1"
        >
          <b-button type="is-danger" @click="deleteImage(f)">Delete</b-button>
        </div>
      </div>

      <!-- For Displaying Image -->
      <img
        src="../../../../assets/logo.png"
        v-if="file.url == null"
        alt="Image"
      />
      <img v-lazy="getUrlFile(file.url)" v-else alt="Image" />

      <br />
      <br />
    </div>

    <!-- For Add Image Button -->
    <b-button type="is-hcc" expanded @click="addMoreImage()">ADD MORE</b-button>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
    listEditedPhoto: {
      default: () => [],
    },
  },
  methods: {
    // for upload file to blob
    uploadFileToBlob(file, indexFile) {
      if (!this.data.questionFile[indexFile].fileNumber) {
        this.data.questionFile[indexFile].fileNumber = 1
      }

      if (!this.data.questionFile[indexFile].status) {
        this.data.questionFile[indexFile].status = ''
      }

      if (!this.data.questionFile[indexFile].fileId) {
        this.data.questionFile[indexFile].fileId =
          Math.floor(10000 + Math.random() * 90000) +
          Math.round(new Date().getTime() / 1000)
      }

      if (this.$route.name == 'editAssessmentSection') {
        if (this.data.questionFile[indexFile].url == null) {
          this.listEditedPhoto.push({
            type: 'create',
            questionId: this.data.questionId || this.data.groupId,
            fileData: file,
            filePath: '',
            fileNumber: this.data.questionFile[indexFile].fileNumber,
            fileId: this.data.questionFile[indexFile].fileId,
          })
        } else {
          if (this.data.questionFile[indexFile].url.includes('blob:')) {
            let isPhotoEdited = false
            this.listEditedPhoto.map(photo => {
              if (this.data.questionFile[indexFile].fileId == photo.fileId) {
                photo.fileData = file
                isPhotoEdited = true
              }
            })

            if (!isPhotoEdited) {
              this.listEditedPhoto.push({
                type: 'create',
                questionId: this.data.questionId || this.data.groupId,
                fileData: file,
                filePath: '',
                fileId: this.data.questionFile[indexFile].fileId,
                fileNumber: this.data.questionFile[indexFile].fileNumber,
              })
            }
          } else {
            this.listEditedPhoto.push({
              type: 'edit',
              questionId: this.data.questionId || this.data.groupId,
              fileData: file,
              filePath: this.data.questionFile[indexFile].url,
              fileId: this.data.questionFile[indexFile].fileId,
              fileNumber: this.data.questionFile[indexFile].fileNumber,
            })
          }
        }
      }

      let blobUrl = URL.createObjectURL(file)

      this.data.questionFile[indexFile].url = blobUrl
    },

    // For Add Image Array
    addMoreImage() {
      let lastData = this.data.questionFile.length

      this.data.questionFile.push({
        file: {
          name: null,
        },
        url: null,
        status: '',
        fileId:
          Math.floor(10000 + Math.random() * 90000) +
          Math.round(new Date().getTime() / 1000),
        fileNumber: lastData + 1,
      })
    },

    // For deleting Image
    deleteImage(indexFile) {
      if (this.$route.name == 'editAssessmentSection') {
        this.listEditedPhoto.push({
          type: 'delete',
          questionId: this.data.questionId,
          fileData: '',
          filePath: this.data.questionFile[indexFile].url,
          fileNumber: this.data.questionFile[indexFile].fileNumber,
          fileId: this.data.questionFile[indexFile].fileId,
        })
      }

      this.data.questionFile.splice(indexFile, 1)
    },

    // For get url backend
    getUrlFile(file) {
      let urlType = file.split(':')

      if (this.$route.name == 'editAssessmentSection' && urlType[0] != 'blob') {
        return process.env.VUE_APP_BACKEND_URL + file
      }

      return file
    },
  },
}
</script>
