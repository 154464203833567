<template>
  <div>
    <!-- For Text -->
    <ValidationProvider
      :vid="`questiontext`"
      name="Question Text"
      rules="required"
      v-slot="{ errors }"
    >
      <b-field :type="errors.length > 0 ? 'is-danger' : null">
        <b-input type="text" v-model="data.question"></b-input>
      </b-field>
      <span class="required">{{ errors[0] }}</span>
    </ValidationProvider>
    <br />

    <!-- For Video -->
    <!-- For Title Video Field -->
    <div class="columns">
      <div class="column is-12">
        <b>Add Video</b>
      </div>
    </div>

    <!-- For uploading video -->
    <div v-for="(file, f) in data.questionFile" :key="f">
      <div class="columns">
        <div class="column is-10">
          <b-field class="file">
            <span class="file-name">{{ file.file.name }}</span>
            <b-upload
              v-model="file.file"
              @input="uploadFileToBlob($event, f)"
              accept="video/*"
              type="is-hcc"
            >
              <a class="button is-hcc">
                <b-icon icon="upload"></b-icon>
                <span>Upload</span>
              </a>
            </b-upload>
          </b-field>
        </div>
      </div>

      <!-- For Displaying Video -->
      <video v-if="file.url != null" width="320" height="240" controls>
        <source v-lazy="getUrlFile(file.url)" />
      </video>
    </div>

    <b-switch type="is-hcc" v-model="data.questionVideoLimit">
      Use Video Limit
    </b-switch>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
    listEditedPhoto: {
      default: () => [],
    },
  },
  methods: {
    // for upload file to blob
    uploadFileToBlob(file, indexFile) {
      if (!this.data.questionFile[indexFile].fileNumber) {
        this.data.questionFile[indexFile].fileNumber = 1
      }

      if (!this.data.questionFile[indexFile].status) {
        this.data.questionFile[indexFile].status = ''
      }

      if (!this.data.questionFile[indexFile].fileId) {
        this.data.questionFile[indexFile].fileId =
          Math.floor(10000 + Math.random() * 90000) +
          Math.round(new Date().getTime() / 1000)
      }

      if (this.$route.name == 'editAssessmentSection') {
        if (this.data.questionFile[indexFile].url == null) {
          this.listEditedPhoto.push({
            type: 'create',
            questionId: this.data.questionId || this.data.groupId,
            fileData: file,
            filePath: '',
            fileId: this.data.questionFile[indexFile].fileId,
            fileNumber: this.data.questionFile[indexFile].fileNumber,
          })
        } else {
          if (this.data.questionFile[indexFile].url.includes('blob:')) {
            let isPhotoEdited = false
            this.listEditedPhoto.map(photo => {
              if (this.data.questionFile[indexFile].fileId == photo.fileId) {
                photo.fileData = file
                isPhotoEdited = true
              }
            })

            if (!isPhotoEdited) {
              this.listEditedPhoto.push({
                type: 'create',
                questionId: this.data.questionId || this.data.groupId,
                fileData: file,
                filePath: '',
                fileId: this.data.questionFile[indexFile].fileId,
                fileNumber: this.data.questionFile[indexFile].fileNumber,
              })
            }
          } else {
            this.listEditedPhoto.push({
              type: 'edit',
              questionId: this.data.questionId || this.data.groupId,
              fileData: file,
              filePath: this.data.questionFile[indexFile].url,
              fileId: this.data.questionFile[indexFile].fileId,
              fileNumber: this.data.questionFile[indexFile].fileNumber,
            })
          }
        }
      }

      let blobUrl = URL.createObjectURL(file)

      this.data.questionFile[indexFile].url = blobUrl
    },

    // For get url backend
    getUrlFile(file) {
      let urlType = file.split(':')

      if (this.$route.name == 'editAssessmentSection' && urlType[0] != 'blob') {
        return process.env.VUE_APP_BACKEND_URL + file
      }

      return file
    },
  },
}
</script>
