<template>
  <div>
    <!-- For Free Text -->
    <ValidationProvider
      :vid="`questionall`"
      name="Question All"
      rules="required"
      v-slot="{ errors }"
    >
      <b-field :type="errors.length > 0 ? 'is-danger' : null">
        <ckeditor v-model="data.questionFreeText" :config="editorConfig" />
      </b-field>
      <span class="required">{{ errors[0] }}</span>
    </ValidationProvider>

    <div v-for="(file, f) in data.questionFile" :key="f">
      <div class="columns">
        <div class="column is-3">
          <b-select
            v-model="data.questionFileType"
            @input="changeFileType()"
            expanded
          >
            <option
              v-for="(type, t) in listFileType"
              :key="t"
              :value="type.value"
            >
              {{ type.title }}
            </option>
          </b-select>
        </div>
        <div class="column is-9">
          <div class="columns">
            <div class="column is-8">
              <span class="file-name">{{ file.file.name }}</span>
            </div>
            <div class="column is-4">
              <template v-if="data.questionFileType == 'image'">
                <b-upload
                  v-model="file.file"
                  @input="uploadFileToBlob($event, f)"
                  accept="image/*"
                  type="is-hcc"
                  expanded
                >
                  <a class="button is-hcc is-fullwidth">
                    <b-icon icon="upload"></b-icon>
                    <span>Upload</span>
                  </a>
                </b-upload>
              </template>
              <template v-else-if="data.questionFileType == 'video'">
                <b-upload
                  v-model="file.file"
                  @input="uploadFileToBlob($event, f)"
                  accept="video/*"
                  type="is-hcc"
                  expanded
                >
                  <a class="button is-hcc is-fullwidth">
                    <b-icon icon="upload"></b-icon>
                    <span>Upload</span>
                  </a>
                </b-upload>
              </template>
            </div>

            <!-- For button delete file from array -->
            <!-- <div
              class="column is-2"
              style="padding-top: 18px"
              v-if="data.questionFile.length > 1"
            >
              <b-button type="is-danger" @click="deleteImage(f)">Delete</b-button>
            </div> -->
          </div>
        </div>
      </div>

      <!-- For Displaying Image -->
      <template v-if="data.questionFileType == 'image'">
        <img
          v-lazy="getUrlFile(file.url)"
          v-if="file.url != null"
          alt="Image"
        />
      </template>
      <!-- For Displaying Video -->
      <template v-else-if="data.questionFileType == 'video'">
        <video
          v-if="file.url != null"
          width="320"
          height="240"
          controls
          v-lazy="getUrlFile(file.url)"
        ></video>
      </template>
    </div>

    <!-- For Add Image Button -->
    <!-- <b-button type="is-hcc" expanded @click="addMoreImage()">ADD MORE</b-button> -->
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
    listEditedPhoto: {
      default: () => [],
    },
  },
  data() {
    return {
      listFileType: [
        {
          title: 'Image',
          value: 'image',
        },
        {
          title: 'Video',
          value: 'video',
        },
      ],
      editorConfig: {
        // The configuration of the editor.
        extraAllowedContent: 'div[class]',
        allowedContent: true,
        // filebrowserUploadMethod: "form",
        // filebrowserUploadUrl: process.env.VUE_APP_BACKEND_URL + "/api/ckeditor/upload-image",
        extraPlugins: 'justify,font',
      },
    }
  },
  mounted() {
    if (
      this.data.questionFileType == null ||
      this.data.questionFileType == ''
    ) {
      this.data.questionFileType = 'image'
    }
  },
  methods: {
    // for upload file to blob
    uploadFileToBlob(file, indexFile) {
      if (!this.data.questionFile[indexFile].fileNumber) {
        this.data.questionFile[indexFile].fileNumber = 1
      }

      if (!this.data.questionFile[indexFile].status) {
        this.data.questionFile[indexFile].status = ''
      }

      if (!this.data.questionFile[indexFile].fileId) {
        this.data.questionFile[indexFile].fileId =
          Math.floor(10000 + Math.random() * 90000) +
          Math.round(new Date().getTime() / 1000)
      }

      if (this.$route.name == 'editAssessmentSection') {
        if (this.data.questionFile[indexFile].url == null) {
          this.listEditedPhoto.push({
            type: 'create',
            questionId: this.data.questionId,
            fileData: file,
            filePath: '',
            fileNumber: this.data.questionFile[indexFile].fileNumber,
            fileId: this.data.questionFile[indexFile].fileId,
          })
        } else {
          this.listEditedPhoto.push({
            type: 'edit',
            questionId: this.data.questionId,
            fileData: file,
            filePath: this.data.questionFile[indexFile].url,
            fileNumber: this.data.questionFile[indexFile].fileNumber,
            fileId: this.data.questionFile[indexFile].fileId,
          })
        }
      }

      let blobUrl = URL.createObjectURL(file)

      this.data.questionFile[indexFile].url = blobUrl
    },

    // For Add Image Array
    addMoreImage() {
      let lastData = this.data.questionFile.length

      this.data.questionFile.push({
        file: {
          name: null,
        },
        url: null,
        status: '',
        // fileType: "image",
        fileNumber: lastData + 1,
      })
    },

    // For deleting Image
    deleteImage(indexFile) {
      if (this.$route.name == 'editAssessmentSection') {
        this.listEditedPhoto.push({
          type: 'delete',
          questionId: this.data.questionId,
          fileData: '',
          filePath: this.data.questionFile[indexFile].url,
          fileNumber: this.data.questionFile[indexFile].fileNumber,
          fileId: this.data.questionFile[indexFile].fileId,
        })
      }

      this.data.questionFile.splice(indexFile, 1)
    },

    // For action when change file type
    changeFileType() {
      this.deleteImage(0)
      this.addMoreImage()
    },

    // For get url backend
    getUrlFile(file) {
      let urlType = file.split(':')

      if (this.$route.name == 'editAssessmentSection' && urlType[0] != 'blob') {
        return process.env.VUE_APP_BACKEND_URL + file
      }

      return file
    },
  },
}
</script>

<style lang="scss" scoped>
.button {
  border-radius: none !important;
}
</style>
