<template>
  <div>
    <div class="column is-10" v-if="data.type == 'subsection'">
      <div class="columns is-multiline">
        <div class="column is-12">
          <div class="columns is-multiline">
            <div class="column is-12">
              <b-field>
                <b-radio type="is-success" disabled></b-radio>
                <div>Subsection - Yes</div>
              </b-field>
            </div>
            <div class="column is-12">
              <b-field>
                <b-radio type="is-success" disabled></b-radio>
                <div>Subsection - No</div>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {}
    }
  }
};
</script>
